/**  =====================
      Basic form elements css start
==========================  **/
.col-form-label {
    font-size: 14px;
}

.select2-container {
    width: 100% !important;
}

.form-group {
    margin-bottom: 1.25em;

    .form-control-lg {
        font-size: 1.25em;
    }

    .form-control-round {
        border-radius: 50px;
    }

    .form-control-static {
        &:focus {
            outline: none;
        }
    }
}

.form-control {
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid rgb(204, 204, 204);

    &:focus {
        border: 1px solid $primary-color;
    }

    &.form-control-normal {
        font-weight: 400;
    }

    &.form-control-bold {
        font-weight: 600;
    }

    &.form-control-capitalize {
        text-transform: capitalize;
    }

    &.form-control-uppercase {
        text-transform: uppercase;
    }

    &.form-control-lowercase {
        text-transform: lowercase;
    }

    &.form-control-variant {
        font-variant: small-caps;
    }

    &.form-control-left {
        text-align: left;
    }

    &.form-control-center {
        text-align: center;
    }

    &.form-control-right {
        text-align: right;
    }

    &.form-control-rtl {
        direction: rtl;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
/*===== Border Inputs start =====*/
$form-border-state: primary, warning, default, danger, success, inverse, info;
$form-border-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $form-border-state {
    $i: index($form-border-state, $var);

    .form-control-#{$var} {
        border-color: nth($form-border-color, $i);
        color: nth($form-border-color, $i);

        &:focus {
            border-color: nth($form-border-color, $i);
        }
    }

    .input-group-#{$var} {
        .input-group-addon {
            background-color: nth($form-border-color, $i);
        }

        .form-control {
            border-color: nth($form-border-color, $i);
        }
    }
}
/*===== Form Input-Text start =====*/
$form-txt-state: primary, warning, default, danger, success, inverse, info;
$form-txt-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $form-txt-state {
    $i: index($form-txt-state, $var);

    .form-txt-#{$var} {
        color: nth($form-txt-color, $i);

        &:focus {
            color: nth($form-txt-color, $i);
        }

        &::-moz-placeholder {
            color: nth($form-txt-color, $i);
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: nth($form-txt-color, $i);
        }

        &::-webkit-input-placeholder {
            color: nth($form-txt-color, $i);
        }
    }
}
/*===== Form Background-inputs start =====*/
$form-bg-state: primary, warning, default, danger, success, inverse, info;
$form-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $form-bg-state {
    $i: index($form-bg-state, $var);

    .form-bg-#{$var} {
        background-color: nth($form-bg-color, $i);
        border-color: nth($form-bg-color, $i);
        color: #fff;

        &:focus {
            background-color: nth($form-bg-color, $i);
            border-color: nth($form-bg-color, $i);
            color: #fff;
        }

        &::-moz-placeholder {
            color: #fff;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: #fff;
        }

        &::-webkit-input-placeholder {
            color: #fff;
        }
    }
}
/*===== Form Validation states start =====*/
$form-valid-state: success, warning, danger;
$form-valid-color: $success-color, $warning-color, $danger-color;
@each $var in $form-valid-state {
    $i: index($form-valid-state, $var);

    .has-#{$var} {
        .col-form-label {
            color: nth($form-valid-color, $i);
        }

        .form-control-success {
            border-color: nth($form-valid-color, $i);
        }
    }
}
/*===== Form-icon css starts =====*/
.left-icon-control {
    position: relative;

    input {
        padding-left: 50px;
    }

    .form-icon {
        position: absolute;
        top: 50%;
        left: 17px;
        margin-top: -12px;
    }
}

.right-icon-control {
    position: relative;

    input {
        padding-right: 50px;
    }

    .form-icon {
        position: absolute;
        top: 7px;
        right: 17px;
    }
}
/*===== Form-icon css ends =====*/
/*===== Basic form elements ends =====*/
/**  =====================
      Form group-add-on css start
==========================  **/
.input-group {
    margin-bottom: 1.25em;
}

.input-group-addon {
    background-color: $primary-color;
    color: $white-txt;
}
// .input-group-button [class*=btn-] {
//     padding: 0;
//     margin-bottom: 0;
// }
.input-group-button .input-group-addon {
    transition: all ease-in 0.3s;

    &:hover {
        @include buttons-hover(theme-color(primary, hover), theme-color(primary, hover));

        .btn {
            @include buttons-hover(theme-color(primary, hover), theme-color(primary, hover));
        }
    }
}

.input-group-dropdown [class*=btn-] {
    margin-bottom: 0;
}
/*===== Form group-add-on ends =====*/
/**  =====================
      Switches css start
==========================  **/
.js-dynamic-disable,
.js-dynamic-enable {
    vertical-align: inherit;
}
/*===== Switches css ends =====*/
/**  =====================
      Radio-button css start
==========================  **/
.form-radio {
    position: relative;

    .form-help {
        position: absolute;
        width: 100%;
    }

    label {
        position: relative;
        padding-left: 1.5rem;
        text-align: left;
        color: #333;
        display: block;
        line-height: 1.8;
    }

    input {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
    }
}

.radio {
    .helper {
        position: absolute;
        top: -0.15rem;
        left: -0.25rem;
        cursor: pointer;
        display: block;
        font-size: 1rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #999;

        &::after {
            transform: scale(0);
            background-color: $primary-color;
            border-color: $primary-color;
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            margin: 0.25rem;
            width: 1rem;
            height: 1rem;
            -webkit-transition: -webkit-transform 0.28s ease;
            transition: -webkit-transform 0.28s ease;
            transition: transform 0.28s ease, -webkit-transform 0.28s ease;
            border-radius: 50%;
            border: 0.125rem solid $primary-color;
        }
    }

    label:hover .helper {
        color: $primary-color;
    }

    input {
        &:checked ~ .helper::after {
            transform: scale(0.5);
        }

        &:checked ~ .helper::before {
            color: $primary-color;
        }
    }

    &.radiofill {
        input:checked ~ .helper::after {
            transform: scale(1);
        }

        .helper::after {
            background-color: $primary-color;
        }
    }

    &.radio-outline {
        input:checked ~ .helper::after {
            transform: scale(0.6);
        }

        .helper::after {
            background-color: #fff;
            border: 0.225rem solid $primary-color;
        }
    }

    &.radio-matrial {
        input ~ .helper::after {
            background-color: #fff;
        }

        input:checked ~ .helper::after {
            transform: scale(0.5);
            box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72);
        }

        input:checked ~ .helper::before {
            background-color: $primary-color;
        }
    }

    &.radio-disable {
        label {
            cursor: not-allowed;
        }
        opacity: 0.7;
    }
}

.radio-inline {
    display: inline-block;
    margin-right: 20px;
}
$radio-bg-state: primary, warning, default, danger, success, inverse, info;
$radio-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $radio-bg-state {
    $i: index($radio-bg-state, $var);

    .radio {
        &.radiofill {
            &.radio-#{$var} {
                .helper::after {
                    background-color: nth($radio-bg-color, $i);
                    border-color: nth($radio-bg-color, $i);
                }

                .helper::before {
                    border-color: nth($radio-bg-color, $i);
                }
            }
        }

        &.radio-outline {
            &.radio-#{$var} {
                .helper::after {
                    background-color: #fff;
                    border: 0.225rem solid nth($radio-bg-color, $i);
                }

                .helper::before {
                    border-color: nth($radio-bg-color, $i);
                }
            }
        }

        &.radio-matrial {
            &.radio-#{$var} {
                input ~ .helper::after {
                    background-color: #fff;
                    border-color: #fff;
                }

                input ~ .helper::before {
                    background-color: nth($radio-bg-color, $i);
                    border-color: nth($radio-bg-color, $i);
                }
            }
        }
    }
}
/*===== Radio Button css ends =====*/
/*===== Border-checkbox css start =====*/
.border-checkbox-section {
    .border-checkbox-group {
        display: inline-block;

        .border-checkbox:checked + .border-checkbox-label:after {
            -webkit-animation: check linear 0.5s;
            animation: check linear 0.5s;
            opacity: 1;
            border-color: $primary-color;

            .border-checkbox-label {
                &:before {
                    border-color: #eee;
                }
            }
        }

        .border-checkbox-label {
            position: relative;
            display: inline-block;
            cursor: pointer;
            height: 20px;
            line-height: 20px;
            padding-left: 30px;
            margin-right: 15px;

            &:after {
                content: "";
                display: block;
                width: 6px;
                height: 12px;
                opacity: 0.9;
                border-right: 2px solid #eee;
                border-top: 2px solid #eee;
                position: absolute;
                left: 4px;
                top: 11px;
                -webkit-transform: scaleX(-1) rotate(135deg);
                transform: scaleX(-1) rotate(135deg);
                -webkit-transform-origin: left top;
                transform-origin: left top;
            }

            &:before {
                content: "";
                display: block;
                border: 2px solid $primary-color;
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
            }
        }
    }

    .border-checkbox {
        display: none;

        &:disabled {
            ~ .border-checkbox-label {
                cursor: no-drop;
                color: #ccc;
            }
        }
    }
}
@include theme-keyframes(check) {
    0% {
        height: 0;
        width: 0;
    }

    25% {
        height: 0;
        width: 6px;
    }

    50% {
        height: 12px;
        width: 6px;
    }
}
$border-checkbox-bg-state: primary, warning, default, danger, success, inverse, info;
$border-checkbox-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $border-checkbox-bg-state {
    $i: index($border-checkbox-bg-state, $var);

    .border-checkbox-section .border-checkbox-group-#{$var} {
        .border-checkbox-label:before {
            border: 2px solid nth($border-checkbox-bg-color, $i);
        }

        .border-checkbox:checked + .border-checkbox-label:after {
            border-color: nth($border-checkbox-bg-color, $i);
        }
    }
}
/*===== zoom Fade-in-checkbox css start =====*/
.checkbox-fade, .checkbox-zoom{
    display: inline-block;
    margin-right: 15px;

    label {
        line-height: 20px;

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            &:checked + .cr > .cr-icon {
                transform: scale(1) rotateZ(0deg);
                opacity: 1;
            }

            + .cr > .cr-icon {
                transform: scale(3) rotateZ(-20deg);
                opacity: 0;
                transition: all 0.3s ease-in;
            }
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    &.fade-in-disable {
        .cr,
        label {
            color: #ccc;
            cursor: no-drop;
        }
    }

    .cr {
        border-radius: 0;
        border: 2px solid $primary-color;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 20px;
        margin-right: 0.5em;
        position: relative;
        width: 20px;

        .cr-icon {
            color: $primary-color;
            font-size: 0.8em;
            left: 0;
            line-height: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
        }
    }
}
.checkbox-fade{
    label {
        line-height: 20px;

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            &:checked + .cr > .cr-icon {
                transform: scale(1) rotateZ(0deg);
                opacity: 1;
            }

            + .cr > .cr-icon {
                transform: scale(3) rotateZ(-20deg);
                opacity: 0;
                transition: all 0.3s ease-in;
            }
        }
    }
}
.checkbox-zoom{
    label {
        line-height: 20px;
        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            &:checked + .cr > .cr-icon {
                transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
                opacity: 1;
            }

            + .cr > .cr-icon {
                transform: scale3d(0.2, 0.2, .1) translate3d(0, 0, 0);
                opacity: 0;
                transition: all 0.3s ease-in;
            }
        }
    }
}
// @keyframes zoomInLeft {
//     0% {
//         opacity: 0;
//
//         animation-timing-function: cubic-bezier(.55, .055, .675, .19)
//     }
//     60% {
//         opacity: 1;
//
//         animation-timing-function: cubic-bezier(.175, .885, .32, 1)
//     }
// }
$fade-in-checkbox-bg-state: primary, warning, default, danger, success, inverse, info;
$fade-in-checkbox-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $fade-in-checkbox-bg-state {
    $i: index($fade-in-checkbox-bg-state, $var);

    .checkbox-fade,.checkbox-zoom {
        &.fade-in-#{$var},&.zoom-#{$var}{
            .cr {
                border: 2px solid nth($fade-in-checkbox-bg-color, $i);
                .cr-icon {
                    color: nth($fade-in-checkbox-bg-color, $i);
                }
            }
        }
    }
}
/*===== Color-checkbox css start =====*/
.checkbox-color {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;

    label {
        display: inline-block;
        position: relative;
        padding-left: 10px;
        line-height: 20px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            width: 20px;
            height: 20px;
            left: 0;
            right: 0;
            text-align: center;
            margin-left: -20px;
            border: 1px solid #ccc;
            border-radius: 0;
            background-color: #fff;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        }

        &::after {
            display: inline-block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: -1px;
            top: 0;
            margin-left: -17px;
            padding-left: 3px;
            padding-top: 1px;
            font-size: 11px;
            color: #fff;
        }
    }

    input[type="checkbox"] {
        opacity: 0;

        &:focus + label::before {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }

        &:checked + label::after {
            font-family: 'FontAwesome';
            content: "\f00c";
        }

        &:disabled + label {
            opacity: 0.65;

            &::before {
                background-color: #eee;
                cursor: not-allowed;
            }
        }
    }

    &.checkbox-circle label::before {
        border-radius: 50%;
    }

    &.checkbox-inline {
        margin-top: 0;
    }
}

.checkbox-danger,
.checkbox-info,
.checkbox-primary,
.checkbox-success,
.checkbox-warning {
    input[type="checkbox"]:checked + label::after {
        color: #fff;
    }
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #2196f3;
    border-color: #2196f3;
}
$background-checkbox-bg-state: primary, warning, default, danger, success, inverse, info;
$background-checkbox-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $background-checkbox-bg-state {
    $i: index($background-checkbox-bg-state, $var);

    .checkbox-#{$var} input[type="checkbox"]:checked + label::before {
        background-color: nth($background-checkbox-bg-color, $i);
    }
}
/*===== Tag input css start =====*/
.bootstrap-tagsinput {
    border: 1px solid $primary-color;
    line-height: 30px;
    border-radius: 2px;

    .tag {
        padding: 6px;
        border-radius: 2px;
    }
}
/**  =====================
      Select-2 css start
==========================  **/
.select2-container--default {
    &:focus {
        border-color: $primary-color;
    }

    &.select2-container--open {
        .select2-selection--single .select2-selection__arrow b {
            border-color: transparent transparent $white-txt transparent;
        }
    }

    .select2-search__field {
        &:focus {
            border: 1px solid $primary-color;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary-color;
    }

    .select2-selection--multiple {
        padding: 3px;

        .select2-selection__choice {
            background-color: $primary-color;
            border: 1px solid $primary-color;
            padding: 5px 15px;
            color: $white-txt;

            span {
                color: $white-txt;
            }
        }

        .select2-search__field {
            border: none;
        }
    }

    &.select2-container--focus .select2-selection--multiple {
        border: 1px solid $primary-color;
    }

    .select2-selection--single {
        color: $white-txt;
        height: auto;

        .select2-selection__rendered {
            background-color: $primary-color;
            color: $white-txt;
            padding: 8px 30px 8px 20px;
        }

        .select2-selection__arrow {
            top: 10px;
            right: 15px;

            b {
                border-color: $white-txt transparent transparent transparent;
            }
        }
    }
}
/*===== Select-2 css ends =====*/
/**  =====================
      Multi-select css start
==========================  **/
.ms-container .ms-list.ms-focus {
    border-color: $primary-color;
    box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5);
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    background-color: $primary-color;
}

.ms-selectable .custom-header,
.ms-selection .custom-header {
    background-color: $primary-color;
    color: #fff;
    text-align: center;
}

.ms-list {
    display: block !important;

    li {
        width: 100%;
    }
}
/*===== Multi-select css ends =====*/
/**  =====================
      Validation-forms css start
==========================  **/
.form-group {
    .messages {
        p {
            margin-bottom: 0;
            transition: all ease-in 0.3s;
        }
    }

    .popover-valid {
        position: absolute;
        right: 40px;
        top: 7px;
    }
}
/*===== Validation-forms css end =====*/
/**  =====================
      Form-wizards css start
==========================  **/
.wizard > .steps .current a {
    background: $primary-color;
    transition: all ease-in 0.3s;
    cursor: pointer;
    border-radius: 2px;
    outline: 1px solid #fff;
    outline-offset: -7px;
    &:hover {
        background: lighten($primary-color, 5%);
        outline-offset: 0;
    }
}

#design-wizard .steps li {
    position: relative;
    z-index: 99;
    margin-bottom: 20px;

    &:after {
        content: "";
        position: absolute;
        height: 2px;
        background: $primary-color;
        width: 100%;
        top: 30px;
        z-index: -1;
    }

    a {
        width: 20%;
        margin: 0 auto;
        text-align: center;
        border-radius: 2px;
    }
}

.wizard > .steps .done a {
    background: $default-color;
}

.card .card-block .wizard-form .steps ul {
    display: block;
}

.card .card-block .wizard-form li {
    margin-right: 0;
}

.wizard > .actions a {
    @extend .btn-primary;
}

.wizard > .content {
    border: 1px solid #ccc;
    border-radius: 2px;
    background: $white-txt;
}
/*===== Form-wizard forms css end =====*/


/*
ALEJANDRA
 */
/*

.col-form-label {
  font-size: 12px!important;
  text-align: left!important;
  padding: 0px!important;
}
.input-group {
  margin-bottom: unset;//?
}
*/
