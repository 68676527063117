.ngx-form-wizard {
  .nav-item{
    a.nav-link {
      background: #eee;
      color: #aaa;
      margin: 0 0.5em 0.5em;
      padding: 1em 1em;
      border-radius: 5px;
      &.active{
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
  .nav-tabs{
    border-bottom: none;
  }
  .tab-pane{
    border: 1px solid $theme-border;
    padding: 20px;
    margin: 8px;
    border-radius: 5px;
  }
}
// .arc-wizard {
//   &.arc-custom {
//     .steps-indicator li:after {
//       border-radius: 5px !important;
//     }
//   }
//   .centered-content {
//     text-align: center;
//   }
//   .wizard-steps{
//     //border-radius: 5px;
//     border:1px solid $theme-border;
//     padding: 20px;
//     background: #fff;
//     margin-top: 30px;
//     padding: 20px;
//   }

//   .small ul.steps-indicator li.current:after,
//   .small ul.steps-indicator li.editing:after {
//     background-color: $primary-color !important;
//   }

//   .large-filled-symbols ul.steps-indicator li.current:after,
//   .large-filled-symbols ul.steps-indicator li.editing:after {
//     background-color: $primary-color !important;
//     color: #fff !important;
//   }

//   .large-filled-symbols ul.steps-indicator li.done:after {
//     color: #fff !important;
//   }

//   .large-empty-symbols ul.steps-indicator li.current:after,
//   .large-empty-symbols ul.steps-indicator li.editing:after {
//     border-color: $primary-color !important;
//     color: $primary-color !important;
//   }
// }
// aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current:after {
//   background-color: #3585b7 !important;
// }
// aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done:after {
//   background-color: #e02291 !important;
// }
// aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:after {
//   background-color: #e28686 !important;
// }

// aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.done:after {
//   background-color: #e02291 !important;
// }

// aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done:after {
//   background-color: #2D2D2D !important;
//   color: #ffffff !important;
//   content: '';
//   font-family: 'FontAwesome';
//   font-size: 29px;
//   // margin: 3px;
//   border: #fff solid 2px;
//   box-shadow: 0 0 0 2px $primary-color;
//   line-height: 1.65;

// }
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):before {
  // background-color: #e02291 !important;
  color: #f1b3b3 !important;


}
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child).done:before {
  background-color:$primary-color !important;
  color: #f1b3b3 !important;
}

// aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.current:after {
//   color: #fff !important;
//   background-color: $primary-color !important;
//   border: #fff solid 2px;
//   box-shadow: 0 0 0 2px $primary-color;
// }
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator {
  margin-bottom: 50px;
}
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):before {
height: 2px !important;
}
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.editing:after {
  color: #fff !important;
  background-color: $primary-color !important;
  border: #fff solid 2px;
  box-shadow: 0 0 0 2px $primary-color;
}

.asignacion-content {
  .input-group {
    a {
      position: absolute;
      right: 15px;
    }
  }
  .form-control {
    background: #F2F2F2!important;
  }
}