.ngx-carousel{
  .bannerStyle {
    background-color: #ccc;
    background-size: cover !important;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    h1 {

    }
  }

  .tile {
    min-height: 200px;
    background-color: #ccc;
    h1 {

    }
  }

  h4 {
    margin: 0;
    padding: 10px 15px;
    text-align: center;
  }

  p {
    margin: 0;
    padding: 0 15px 10px;
    text-align: center;
  }

  .wBg {
    background: white;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  nav {
    border-bottom: 1px solid #ccc;
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 12;
    h1 {
      margin: 0;
      padding: 10px;
      text-align: center;
    }
  }

  .carBtn {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
    border-radius: 999px;
  }

  .tile {
    height: auto;
    background: #fff;
    img {
      width: 100%;
    }
  }
  .carousel-control-prev, .carousel-control-next{
    opacity: 1;
    background-color: transparent;
    cursor: pointer;
    border: none;
    i{
      color: #fff;
      font-size: 35px;
      opacity: 0.5;

    }
  }
  .carousel-control-prev:hover{
    i{
      opacity: 1;
    }
  }
  .carousel-control-next:hover{
    i{
      opacity: 1;
    }
  }
}
