$black: #000;
@import "ngx-scss/waves";
@import "ngx-scss/tab";
@import "ngx-scss/list";
@import "ngx-scss/pnotify";
@import "ngx-scss/rating";
@import "ngx-scss/carousel";
@import "ngx-scss/tag-input";
@import "ngx-scss/table";
@import "ngx-scss/accordion";
@import "ngx-scss/task-board";
@import "ngx-scss/ripple";
@import "ngx-scss/range-slider";
@import "ngx-scss/tour";
@import "ngx-scss/form-wizard";

.input-group-append .input-group-text, .input-group-prepend .input-group-text {
  background-color: #448aff;
  border-color: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.header-navbar .navbar-wrapper {
  .navbar-container .header-notification {
    .show-notification {
      display: block;
    }
    .profile-notification {
      display: block;
      li {
        border-top: none;
      }
    }
    .show-notification li {
      border-top: none;
    }
  }
  .header-search .main-search {
    .input-group .input-group-prepend.search-close {
      display: none;
    }
    &.open .input-group {
      .input-group-append.search-btn i,
      .input-group-prepend.search-close i {
        background-color: transparent;
        color: #000;
      }
      .input-group-prepend.search-close {
        display: flex;
      }
    }
    .input-group .input-group-append .input-group-text,
    .input-group .input-group-prepend .input-group-text {
      background-color: transparent;
      color: #fff;
      border: none;
    }
  }
}

.showChat,
.showChat_inner {
  display: block;
  height: 100vh;
  overflow: hidden;
}

.users-main {
  height: 100vh;
  width: 100%;
  position: absolute;
}

.pcoded .pcoded-header .p-chat-user a.back_friendlist,
.pcoded .pcoded-header .chat-inner-header a.back_chatBox {
  color: #222;
}
@media only screen and (max-width: 992px) {
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
    display: block;
    overflow: hidden;
  }

  .showChat, .showChat_inner {
    top: 112px;
  }
  .header-navbar .navbar-wrapper .navbar-container .nav-right{
    &.nav-on{
      overflow: visible;
    } &.nav-on.nav-off{
      overflow: hidden;
    }
  }
}

.chat-reply-box {
  bottom: 107px;
}

.pcoded {
  .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y:hover>.ps__thumb-y, .ps__thumb-y {
    width: 3px;
    background-color: #000;
    opacity: 0.4;
  }
  perfect-scrollbar>.ps.ps--active-y>.ps__rail-y, perfect-scrollbar>.ps.ps--active-y>.ps__rail-y:hover{
    width: 0;
  }
  perfect-scrollbar {
    >.ps{
      height: 100%;
    }
  }
  #styleSelector {
    .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y:hover>.ps__thumb-y, .ps__thumb-y {
      width: 6px;
    }
    .theme-color a {
      margin: 5px 4px 5px 0;
    }
  }
  &[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar{
    position: fixed !important;
  }
  &[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a{
    line-height: 2.3;
  }
}

.login .container-fluid {
  margin-top: 56px;
}

// ng bootstrap accordion
.ngb-accordion{
  .card{
    border-top:1px solid #ddd;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    .card-header{
      border-radius: 0;
      padding: 14px 20px;
      a{
        transition: all 0.5s ease-in-out;
        font-weight: 600;
      }
      &:hover,&:focus{
        a{
          color: $color-blue;
          background: #fff;
          &:hover,&:focus{
            background: #fff;
          }
        }
      }
      +.card-body{
        padding-top: 0;
      }
    }
  }
  &.color-accordion{
    .card{
      border-top:1px solid #fff;
      .card-header{
        transition: all 0s ease-in-out;
        a{
          transition: all 0s ease-in-out;
        }
        &:hover,&:focus,&.active{
          background: $color-blue;
          a{
            color: #fff;
            background: $color-blue;
            &:hover,&:focus{
              color: #fff;
              background: $color-blue;
            }
          }
        }
        +.card-body{
          padding-top: 20px;
        }
      }
    }
  }
}

// tab below bootstrap
.below-tabs {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  .nav-tabs, .tab-content {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .nav-tabs {
    .nav-link {
      border: 1px solid transparent;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
    border-top: 1px solid #ddd;
    border-bottom: transparent;
    .nav-item.show .nav-link, .nav-link.active {
      color: #464a4c;
      background-color: #fff;
      border-color: #fff #ddd #ddd;
      margin-top: -1px;
    }
  }
}

/* tab material start */

.md-tabs {
  &.md-card-tabs {
    .nav.nav-tabs {
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
      border: none;
      margin-bottom: 30px;
      transition: all 0.3s ease-in-out;
    }
  }
  .nav-link {
    &.active {
      border-bottom: 4px solid $color-blue;
      border-bottom: 4px solid $color-blue;
      &:focus, &:hover {
        border-bottom: 4px solid $color-blue;
      }
    }
    &:hover,
    &:focus{
      border-bottom:4px solid #ddd;
    }
    transition: all 0.4s ease-in-out;
    border-bottom: 4px solid transparent;
  }
  &.md-below-tabs {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    .nav-tabs, .tab-content {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .nav-tabs, .nav-item:first-child {
      border-top: 1px solid #ddd;
      border-bottom: 0px solid transparent;
      margin-top: -1px;
    }
    .nav-link {
      &.active {
        border-top: 4px solid $color-blue;
        border-top: 4px solid $color-blue;
        border-bottom: 0px solid transparent;
        &:focus, &:hover {
          border-top: 4px solid $color-blue;
          border-bottom: 0px solid transparent;
        }
      }
      transition: all 0.4s ease-in-out;
      border-top: 0px solid transparent;
    }
    .nav-tabs .nav-link {
      &:hover {
        border-top: 4px solid #ddd;
        border-bottom: 0px solid transparent;
      }
      &.active:hover {
        border-top: 4px solid $color-blue;
      }
    }
  }
  &.md-left-tabs {
    .nav-item {
      width: 100%;
      &:first-child {
        border-bottom: 0px;
      }
      .nav-link {
        border: 0px solid transparent;
        padding: 10px 0 !important;
        margin: 10px 0;
      }
    }
    .nav-link.active {
      border-left: 4px solid $color-blue;
      border-bottom: 0px solid transparent;
      &:focus, &:hover {
        border-left: 4px solid $color-blue;
        border-bottom: 0px solid transparent;
      }
    }
    .nav-item .nav-link.active {
      border: 0px solid transparent;
      border-left: 4px solid $color-blue;
    }
    .nav-tabs {
      .nav-link {
        &.active:hover {
          border-left: 4px solid $color-blue;
        }
        &:hover {
          border: 0px solid transparent;
          border-left: 4px solid #ddd;
        }
      }
      border-bottom: 0px solid transparent;
      display: table-cell;
      min-width: 120px;
      vertical-align: top;
      width: 150px;
    }
    .tab-content {
      display: table-cell;
      top: 0;
      position: absolute;
      left: 150px;
    }
  }
  &.md-right-tabs {
    .nav-item {
      width: 100%;
      &:first-child {
        border-bottom: 0px;
      }
      .nav-link {
        border: 0px solid transparent;
        padding: 10px 0 !important;
        margin: 10px 0;
      }
    }
    .nav-link.active {
      border-right: 4px solid $color-blue;
      border-bottom: 0px solid transparent;
      &:focus, &:hover {
        border-right: 4px solid $color-blue;
        border-bottom: 0px solid transparent;
      }
    }
    .nav-item .nav-link.active {
      border: 0px solid transparent;
      border-right: 4px solid $color-blue;
    }
    .nav-tabs {
      .nav-link {
        &.active:hover {
          border-right: 4px solid $color-blue;
        }
        &:hover {
          border: 0px solid transparent;
          border-right: 4px solid #ddd;
        }
      }
      border-bottom: 0px solid transparent;
      display: table-cell;
      min-width: 120px;
      vertical-align: top;
      width: 150px;
      float: right;
    }
    .tab-content {
      display: table-cell;
      top: 0;
      position: absolute;
      right: 150px;
    }
  }
  &.md-image-tab {
    .nav-link {
      position: relative;
    }
    .nav-item, .nav-link, .nav-tabs {
      border: 0px solid transparent !important;
    }
    img {
      width: 100px;
      border: 4px solid $color-blue;
      + .quote {
        position: absolute;
        height: 25px;
        width: 25px;
        border-radius: 100%;
        bottom: 10px;
        right: 70px;
      }
    }
    .quote i {
      border-radius: 50%;
      padding: 3px 5px;
    }
    .nav-link {
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .md-tabs.md-left-tabs {
    .nav-tabs {
      width: 100%;
      display: block;
    }
    .tab-content {
      display: block;
      position: relative;
      left: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .md-tabs.md-right-tabs {
    .nav-tabs {
      width: 100%;
      display: block;
    }
    .tab-content {
      display: block;
      position: relative;
      left: 0;
    }
  }
}

@media only screen and (max-width: 360px) {
  .card-comment .comment-desc {
    padding-bottom: 10px;
  }

}

/* advance notify */
.small-icon .icon {
  width: auto !important;
  height: auto !important;
}

.no-icon .icon {
  display: none;
}

.simple-notification-wrapper {
  z-index: 10001 !important;
  width: auto !important;
  max-width: 450px;
  .simple-notification.info{
    &.bg-c-blue{
      background:linear-gradient(45deg, $color-blue, lighten($color-blue,10%)) !important;
    }
  }
  .simple-notification.error{
    &.bg-c-pink{
      background:linear-gradient(45deg, $color-pink, lighten($color-pink,10%)) !important;
    }
  }
}

/* forms advance elements */
.tag-chips {
  .ng2-tag-input {
    border-bottom: 1px solid $color-blue !important;
    border: 1px solid $color-blue;
    line-height: 30px;
    border-radius: 2px;
    padding: 6px !important;
    tag {
      color: #fff !important;
      background: linear-gradient(45deg, #00bcd4, #08e3ff) !important;
      border-radius: 2px !important;
      &.tag--editing {
        color: #333 !important;
        background: #fff !important;
      }
    }
    &.bootstrap {
      @extend tag;
    }
    &.minimal {
      tag {
        border-radius: 15px !important;
      }
    }
    &.dark tag {
      background: linear-gradient(45deg, #150909, #bbb8b8) !important;
    }
    &.danger tag {
      background: linear-gradient(45deg, #FF5370, #ff869a) !important;
    }
    &.success tag {
      background: linear-gradient(45deg, #2ed8b6, #59e0c5) !important;
    }
    &.warning tag {
      background: linear-gradient(45deg, #FFB64D, #ffcb80) !important;
    }
    &.minimal-dark tag {
      border-radius: 15px !important;
      background: linear-gradient(45deg, #150909, #bbb8b8) !important;
    }
    &.minimal-danger tag {
      border-radius: 15px !important;
      background: linear-gradient(45deg, #FF5370, #ff869a) !important;
    }
    &.minimal-success tag {
      border-radius: 15px !important;
      background: linear-gradient(45deg, #2ed8b6, #59e0c5) !important;
    }
    &.minimal-warning tag {
      border-radius: 15px !important;
      background: linear-gradient(45deg, #FFB64D, #ffcb80) !important;
    }
  }
  .tag__text {
    line-height: 2;
    font-size: 11px;
    font-weight: 400;
  }
  delete-icon svg {
    height: 12px !important;
    line-height: 1;
    vertical-align: top !important;
    margin-top: 5px;
    path {
      fill: #fff !important;
    }
  }
  .ng2-tags-container tag {
    padding: 3px 6px !important;
    height: 27px !important;
  }
  delete-icon:hover {
    transform: none !important;
  }
  .ng2-tag-input__text-input {
    height: auto !important;
  }
}

/* form bootstrap date picker */
.custom-range {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  &.focused {
    background-color: #e6e6e6;
  }
  &.range, &:hover {
    background-color: $color-blue;
    color: white;
  }
}

.faded {
  opacity: 0.5;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;
  &:hover, &.focused {
    background-color: #e6e6e6;
  }
}

.weekend {
  background-color: #999;
  border-radius: 1rem;
  color: white;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: block;
    flex: 240px;
  }
}

/* form validation */
.tooltip-error {
  position: absolute;
  right: 30px;
  top: 7px;
}

/* form select */
ng-select.ng-select > div {
  border: none;
  > div.single {
    background-color: $color-blue;
    color: #fff;
    padding: 8px 30px 8px 20px;
    border-radius: 4px;
    height: auto;
  }
  &.disabled > div.single {
    background-color: #aaa;
    color: #fff;
  }
  > div.single > div {
    &.clear:hover, &.toggle:hover {
      background-color: transparent;
    }
    &.clear {
      color: #fff;
    }
    &.toggle {
      color: #fff;
      font-size: 9px;
    }
    &.clear, &.toggle {
      width: 0;
    }
  }
}

.ng-select select-dropdown .highlighted {
  background-color: $color-blue;
}

.famfamfam-flags {
  display: inline-block;
  margin-right: 6px;
  width: 16px;
}

ng-select.ng-select > div > div {
  &.multiple {
    border: 1px solid #aaa;
    padding: 6px 10px 6px 10px !important;
    border-radius: 4px;
    > div.option {
      span.deselect-option {
        color: #fff !important;
        font-size: 10px !important;
        vertical-align: middle;
        font-weight: bold;
        margin-right: 2px;
      }
      background-color: $color-blue !important;
      border: 1px solid $color-blue !important;
      padding: 5px 15px !important;
      color: #fff !important;
      margin: 0 2px 2px !important;
    }
  }
  &.single > div {
    &.placeholder {
      color: #fff;
    }
    &.clear {
      font-size: 10px;
      margin-right: 14px;
      font-weight: bold;
    }
  }
}

.tag-select {
  .bootstrap tag {
    padding: 5px 10px !important;
    height: auto !important;
    z-index: 0;
    line-height: 25px !important;
    background: $color-blue !important;
    delete-icon{
      &:hover{
        transform: scale(1);
      }
      svg{
        height: 27px !important;
      }
    }
  }
  tag-input-form {
    padding: 0px 10px 0px 10px !important;
  }
  .ng2-tag-input {
    border-bottom: 1px solid #aaa !important;
    border: 1px solid #aaa;
    padding: 5px !important;
    border-radius: 4px;
  }
}

/* data table */
ngx-datatable.data-table {
  border: 1px solid #eceeef;
}

.data-table {
  datatable-header {
    //border-bottom: 2px solid #ccc;
  }
  datatable-footer {
    //border-top: 2px solid #ccc;
    padding: 0 0.75rem;
    background-color: #eceeef;
    //box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.15);
    text-align: left;
  }
  .empty-row {
    padding: 0.75rem;
    text-align: center;
  }
  .datatable-row-wrapper:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  datatable-body-cell {
    padding: 0.75rem;
    + datatable-body-cell {
      border-left: 1px solid #eceeef;
      // background: #00bcd4;
    }
  }
  datatable-header-cell {
    .datatable-header-cell-label {
      padding: .75rem 30px .75rem .75rem;
      background-color: #4b444a;
      color: #fff;
    }
    + datatable-header-cell {
      border-left: 1px solid #eceeef;
    }
    &.sortable {
      &:before, &:after {
        font-family: 'IcoFont' !important;
        position: absolute;
        top: 10px;
        color: #ccc;
        font-size: 20px;
      }
    }
  }
}

.datatable-header-cell-wrapper, .datatable-header-cell-label {
  display: block;
}
.dt-cust-search.f-right{
  label{
    display: inline-block;
  }
  .form-control{
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}
.data-table {
  datatable-header-cell.sortable {
    &:after {
      content: "\eb53";
      right: 10px;
    }
    &:before {
      content: "\eb56";
      right: 15px;
    }
    &.sort-active {
      &.sort-desc:after, &.sort-asc:before {
        color: #666;
      }
    }
  }
  &.scroll .datatable-body, &.fullscreen .datatable-body {
    height: 500px !important;
  }
  datatable-scroller {
    width: auto !important;
  }
}

.datatable-footer {
  //border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
  .page-count {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem;
  }
  .datatable-pager {
    margin: 0 10px;
    li {
      vertical-align: middle;
      &.disabled a {
        color: #3A436C !important;
        background-color: transparent !important;
      }
      &.active a {
        //background-color: $color-blue;
        background: #8597EB;
        color: #fff;
      }
    }
    a {
      padding: 0 6px;
      border-radius: 3px;
      margin: 6px 3px;
      text-align: center;
      vertical-align: top;
      color: rgba(0, 0, 0, 0.54);
      text-decoration: none;
      vertical-align: bottom;
      &:hover {
        color: rgba(0, 0, 0, 0.75);
        background-color: rgba(158, 158, 158, 0.2);
      }
    }
    .datatable-icon-left, .datatable-icon-skip, .datatable-icon-right, .datatable-icon-prev {
      font-size: 20px;
      line-height: 20px;
      padding: 0 3px;
    }
  }
}

.datatable-icon-right:before {
  content: "\eb27";
  font-family: 'icofont';
  font-style: normal;
}

.datatable-icon-skip:before {
  content: "\eb3a";
  font-family: 'icofont';
  font-style: normal;
}

.datatable-icon-left:before {
  content: "\eb26";
  font-family: 'icofont';
  font-style: normal;
}

.datatable-icon-down:before {
  content: "\eb28";
  font-family: 'icofont';
  font-style: normal;
}

.datatable-icon-prev:before {
  content: "\eb39";
  font-family: 'icofont';
  font-style: normal;
}

.dt-desc {
  th, td {
    padding: .75rem;
  }
  padding: 0.45rem 50px;
  table {
    min-width: 400px;
  }
}

.datatable-row-detail {
  //background-color: #f9f9f9;
  //box-shadow: inset 0px 2px 9px -6px rgba(0, 0, 0, 0.68);
}

.expandable {
  datatable-body-cell + datatable-body-cell, datatable-header-cell + datatable-header-cell {
    width: calc(calc(100% - 50px) / 5) !important;
  }
  .datatable-body-row, .datatable-header-inner {
    width: 100% !important;
  }
}

.datatable-row-center, .datatable-scroll, .data-table.expandable datatable-scroller {
  width: 100% !important;
}

.ngx-datatable.data-table {
  &.single-selection .datatable-body-row.active:hover .datatable-row-group, &.multi-selection .datatable-body-row.active:hover .datatable-row-group, &.single-selection .datatable-body-row.active .datatable-row-group, &.multi-selection .datatable-body-row.active .datatable-row-group {
    background: $color-blue !important;
    color: #FFF !important;
  }
}

.selection-cell .datatable-body-cell.active {
  background: $color-blue !important;
  color: #FFF !important;
}

.selected-column label {
  margin-bottom: 0px;
}

.dt-chk {
  label {
    margin-bottom: 0;
  }
  .datatable-body-cell {
    line-height: 1 !important;
    overflow: hidden;
  }
}

.full-data-search {
  margin-left: 0.5em;
  display: inline-block;
  width: auto !important;
}

.full-data-show-entry {
  width: 75px;
  display: inline-block;
}

.full-data-table {
  .glyphicon {
    font-family: IcoFont;
    position: absolute;
    right: 14px;
    font-size: 20px;
    top: 9px;
    &.glyphicon-triangle-bottom:before {
      content: "\eb53";
    }
    &.glyphicon-triangle-top:before {
      content: "\eb56";
    }
  }
  th {
    position: relative;
  }
}


$icon-color-ang: #4099ff,#f78ea4,#2ed8b6,#f1c40f,#ab7967,#39ADB5,#7C4DFF,#FF5370;
.pcoded {
  &[nav-type="st1"] {
    $i: 1;
    @for $k from 1 through 3 {
      @each $value in $icon-color-ang{
        .d-color {
          &:nth-child(#{$i + 1}) {
            ul > li > a > .pcoded-micon {
              color: $value !important;
            }
          }

          $i: $i+1;
        }
      }
    }
  }

  &[nav-type="st1"],
  &[nav-type="st2"] {
    .pcoded-item.pcoded-left-item {
      > li {
        > a > .pcoded-micon {
          b {
            display: none;
          }
        }
      }
    }
  }
}

.dark .chat-card .received-chat .msg,
.dark .chat-card .send-chat .msg {
  background-color: transparent;
}

.dark .data-table datatable-footer {
  background-color: #808080;
}

.card-list-img {
  height: 75px;
  width: 75px;
  border-radius: 5px;
}

.basic-list, .list-view-media {
  margin-bottom: 30px;
}

.basic-list {
  li {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
  }
  &.list-icons li, &.list-icons-img li {
    margin-left: 50px;
    position: relative;
  }
}

.list-icons i {
  left: -64px;
  top: 0;
  bottom: -1px;
  width: 64px;
  padding: 8px 0;
  float: left;
  position: absolute;
}

.basic-list p {
  margin: 0;
}

.list-icons-img img {
  left: -60px;
  top: 0;
  bottom: -1px;
  width: 40px;
  position: absolute;
  border-radius: 5px;
}

.list-view li {
  button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .user-card {
    margin-bottom: 20px;
  }
  &:last-child .user-card {
    margin-bottom: 0;
  }
}

.breadcrumb-item {
  float: left;
}

.google-visualization-tooltip {
  transform: rotate(30deg);
}

.current-rating {
  top: 0;
}

ngb-carousel {
  width: inherit;
  height: inherit;
}
//======================

.carousel-inner {
  overflow: visible;
}

.carousel-item {
  //display: flex !important;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.2s ease-in-out, visibility 1.2s;
  z-index: -1;
  display: block;
  position: absolute;
  img{
    width: 100%;
  }
  &.active{
    opacity: 1;
    visibility: visible;
    z-index: 10;
    position: relative;
  }
}
.carousel-control-prev {
  z-index: 20;
}
.carousel-control-next {
  z-index: 20;
}

.carousel-indicators{
  z-index: 20;
}


//====================

.ngx-carousel{
  .bannerStyle {
    background-color: #ccc;
    background-size: cover !important;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    h1 {

    }
  }

  .tile {
    min-height: 200px;
    background-color: #ccc;
    h1 {

    }
  }

  h4 {
    margin: 0;
    padding: 10px 15px;
    text-align: center;
  }

  p {
    margin: 0;
    padding: 0 15px 10px;
    text-align: center;
  }

  .wBg {
    background: white;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  nav {
    border-bottom: 1px solid #ccc;
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 12;
    h1 {
      margin: 0;
      padding: 10px;
      text-align: center;
    }
  }

  .carBtn {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
    border-radius: 999px;
  }

  .tile {
    height: auto;
    background: #fff;
    img {
      width: 100%;
    }
  }
  .carousel-control-prev, .carousel-control-next{
    opacity: 1;
    background-color: transparent;
    cursor: pointer;
    border: none;
    i{
      color: #fff;
      font-size: 35px;
      opacity: 0.5;

    }
  }
  .carousel-control-prev:hover{
    i{
      opacity: 1;
    }
  }
  .carousel-control-next:hover{
    i{
      opacity: 1;
    }
  }
}
.wather-card {
  .progress-bar {
    justify-content: flex-start;
  }
}

.card .card-header + .card-block-hid .card-block-small {
  padding-top: 10px !important;
}

.card-comment:last-child .card-block-small{
  border-bottom:none;
}

canvas {
  width: 100% !important;
}


.chat-control .form-control:focus{
  box-shadow: none;
}

.br-horizontal .br-unit.br-active,
.br-horizontal .br-unit.br-selected,
.br-movie .br-unit.br-active,
.br-movie .br-unit.br-selected,
.br-vertical .br-unit.br-active,
.br-vertical .br-unit.br-selected {
  background-color: #449bff !important;
}

.br-square .br-unit.br-active,
.br-square .br-unit.br-selected {
  border: 2px solid #449bff !important;
  color: #449bff !important;
}

.br-fontawesome .br-selected:after,
.br-default .br-selected:after,
.br-fontawesome-o .br-selected:after,
.br-fontawesome-o .br-fraction:after {
  color: #449bff !important;
}

.br-horizontal .br-unit,
.br-movie .br-unit,
.br-vertical .br-unit {
  background-color: rgba(114, 180, 255, 0.4) !important;
}

.br-square .br-unit {
  border: 2px solid rgba(114, 180, 255, 0.4) !important;
}

.form-control:focus ~ .input-group-btn .btn-secondary {
  color: #fff;
}
.md-image-tab .nav-link{
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .carousel-caption {
    display: none;
  }
}
.main-body{
  margin-top: 40px;
}

.header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-addon{
  line-height: 1.5;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-control:lang(en)::before {
  content: "Browse";
}
.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.pcoded[layout-type="dark"], body.dark {
  .page-item.active a {
    background-color: $primary-color;
  }

  google-chart rect {
    fill: transparent;
  }

  .popover-header,
  .taskboard-task-title,
  .touranchor--is-active,
  .well.ngb-drop-zone,
  .cal-month-view .cal-cell-row:hover,
  .google-visualization-table-table {
    color: $dark-layout-color;
  }

  .cal-month-view .cal-cell-row .cal-cell:hover, .cal-month-view .cal-cell.cal-has-events.cal-open,
  .taskboard-header {
    color: $inverse-color;
  }

  .node-content-wrapper-focused {
    background: #e7f4f9;
    color: $inverse-color;
  }

  .node-content-wrapper:hover {
    background: #f7fbff;
    color: $inverse-color;
  }

  .node-content-wrapper-active, .node-content-wrapper-focused, .node-content-wrapper:hover {
    -webkit-box-shadow: inset 0 0 1px #999;
    box-shadow: inset 0 0 1px #999;
  }

  .node-content-wrapper-active, .node-content-wrapper.node-content-wrapper-active:hover, .node-content-wrapper-active.node-content-wrapper-focused {
    background: #beebff;
    color: $inverse-color;
  }

  perfect-scrollbar>.ps.ps--scrolling-y>.ps__rail-y,
  .ps__rail-x:focus, .ps__rail-x:hover, .ps__rail-y:focus, .ps__rail-y:hover {
    background-color: transparent;
  }

  .btn-light {
    color: #fff;
    &:hover {
      color: $inverse-color;
    }
  }

  .bg-light {
    background-color: transparent !important;
  }

}
// agregado por Chris

.txt-blanco {
  color: #FFF !important;
  a{
    color: #FFF !important;
  }
}
.contenido {
  //background-color: #6E7287;
  //background-color: #353647;
  background-color: $white-txt;
  padding: 20px;
  //padding-bottom: 50px;
  //border: solid 1px #545767;
  // border-radius: 5px;
}
.login-logo {
  padding-bottom: 20px;
}
.login-pagina {

}
//.aura-card {
//  background-color: #6E7287;
//  padding: 20px;
//  //padding-bottom: 50px;
//  border: solid 1px #545767;
//  border-radius: 5px;
//}
.fix-modal {
  position: fixed !important;
  right: 10px !important;
  top: 10px !important;
  font-size: 18px !important;
  color: #FFF !important;
  display: block;
  z-index: 9999;
}
.modal-body{
  padding: 0;
}
//.modal-header .close {
//  margin: 0;
//  position: absolute;
//  top: -10px;
//  right: -10px;
//  width: 23px;
//  height: 23px;
//  border-radius: 23px;
//  background-color: #00aeef;
//  color: #ffe300;
//  font-size: 9px;
//  opacity: 1;
//  z-index: 10;
//}
.titulo {
  //color: #A1A7C5;
  //color: #ADB4D5;
  //color: #C9D2FB;
  //color: #CCD6FF;
  color: #FAFBFF;
  text-align: left;
  padding-bottom: 25px;
  a{
    color: #898EA8 !important;
    &:hover {
      color: #ADB4D5 !important;
    }
  }
  .breadcrumb-item {
    &:before{
      color: #898EA8;
    }
  }
}
.img-border {
  border-radius: 5px;

}
.header-navbar .navbar-wrapper .navbar-logo {
  padding-left: 30px !important;
}
.pcoded-hasmenu .pcoded-submenu {
  border-top: 1px solid #3b4650 !important;
}
//seguridad y control
.page-encabezado{
  //background: #4D4E64;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 50px;
  margin-top: 40px;
  h1{
    color: $light-color;
    font-size: 20px;
    padding: 10px;
    font-weight: bold;
  }
  ul {
    padding-left:60px;
  }

}
.titulo-seguridad {
  //color: #14FDD1;
  a {
    //color: #14FDD1 !important;
    font-size: 12px;
    font-weight: 100;
  }


}
.color-glaze {
  color: #979cb7;

}
.ayuda {
  text-align: right;
  padding: 10px;
}
.box-ayuda {
  height: 30px;
  width: 30px;
  display: block;
  color: #FFF;
  background: #5D6070;
  border-radius:100%;
  padding: 10px;
  text-align: center;
  line-height: 1;
  float: right;
  margin-right: 15px;
  a {
    color: #FFF;
    font-weight: normal;
    }
  &:hover {
      background: #FFF;
    }
}
.tab-seguridad {
  color: #222;
  left: 0;
  top:0;
  .nav-tabs {
    border-bottom: solid 1px #bebebe;
  }
  .tab-content{
    background: #fff;
    padding: 20px 2px 20px 2px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: solid 1px #bebebe;
    border-top: none;
    font-size: 10pt;
    @media only screen and (min-width: 1500px) {
      font-size: 11pt;
    }
    p {
      font-size: 10pt;
    }
  }
  .nav-link {
    // @include media-breakpoint-down(lg) {
    //   padding: 15px;
    // }
    // @include media-breakpoint-down(md) {
    //   padding: 15px 5px;
    // }
    background: #cfcfcf;
    color:$primary-color;
    margin-right: 3px;
    border-bottom: solid 1px #bebebe;
    //padding-right: 90px;
    padding: 15px 70px 15px 15px;
    &:hover{
      border-color: #ff5c62;
      background: #ff5c62;
      color: #FFF;
    }
    &.active {
      background: #ffff;
      color: $primary-color;
      border: solid 1px #bebebe !important;
      border-bottom: solid 1px #fff !important;
      font-weight: bold;

    }
  }
  .foto-mine-table {
    width: 60px;
    text-align: center;
    .btn {
      padding: 10px 0px;
    }
  }
  .tabla-head {
    tr {
      background: #353646;
    }
    background-color: #353646;
     &th {
      border: none;
    }
  }
  .table {
    border-top: none;
    tr {
      background: #575E7E;
      &:first-child {
        background: #353646;
        th, td {
          border: 0;
          background: #353646;
        }
      }
      &:nth-child(odd){
        background: #3F4051;
      }

    }
    th, td {
        border: none;
        border-left: 1px solid #494B62;
        //background: #3F4051;
      }
  }
}
.search-chris {
  display: block;
  float: right;
  position: absolute;
  width: 450px;
  right: 0;
  top:0;
  padding-top: 22px;
  z-index: 1;
  form {
    float: left;
  }
  input {
    width: 180px;
    float: left;
    margin-right: 5px;
    padding: 20px;
    border-radius: 4px;
    background: #979cb7;
    border: solid 1px #979cb7;
    color: #495057;
    ::placeholder{
      color: #495057 !important;
    }
  }
   .ng-select  {
    width: 210px;
    float: left;
    padding: 0 !important;
    margin-right: 5px;
    border-radius: 4px;
    }
  ng-select.ng-select > div > div.multiple {
    padding:5px !important;
    height: 42px;
    overflow: hidden;
    background: #979cb7;
    color: #495057;
    ::placeholder {
      color:#6c757d;
    }

    div.option {
      background: orange !important;
      margin: 0 !important;
      padding: 4px !important;
      border: 0 !important;
      margin-left: 2px !important;
      margin-bottom: 2px !important;
    }
  }
  .btn {
    float: left;
    border:0;
    color: #FFF;
    background: none;
    padding: 10px;
  }
}
.mi-tab{
  position: relative;
}

.chris-snip {
  //border: 1px solid red;
  background: #575e7e;
  //background: #3a5684;
  padding: 10px;
  max-height: 240px;
  height: 240px;
  border-radius: 5px;
  margin-bottom: 30px;
  //margin-left: 5px;
  h1 {
    font-size: 13pt;
    text-align: center;
    color: #FFF;
    min-height: 45px;
  }
  span {
    padding-top:20px;
    font-size: 22pt;
    color: #FFF;
  }
}
.chris-snip-1 {
  background: #266050;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
  h1 {
    font-size: 13pt;
    text-align: center;
    color: #FFF;
    //min-height: 45px;
  }
  span {
    //padding-top:20px;
    font-size: 22pt;
    color: #FFF;
  }
  p{
    margin: 0;
    padding: 0;
    color: #63e1be;

  }
}
.chris-snip-2 {
  background: #1c4b3e;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
  h1 {
    font-size: 13pt;
    text-align: center;
    color: #FFF;
    //min-height: 45px;
  }
  span {
    //padding-top:20px;
    font-size: 22pt;
    color: #FFF;
  }
  p{
    margin: 0;
    padding: 0;
    color: #63e1be;

  }
}
.chris-mod {
  //background: #dce1ff;
  background: #FFF;
  padding: 10px;
  border-radius: 5px;
  //margin-left: 10px;
  margin-bottom: 30px;
  min-height: 240px;
  h1 {
    font-size: 13pt;
    color: #353646;
    text-align: center;
  }
}
.chris-mod-1 {
  //background: #dce1ff;
  background: none;
  padding: 10px;
  border-radius: 5px;
  //margin-left: 10px;
  margin-bottom: 30px;
  min-height: 240px;
  h1 {
    font-size: 13pt;
    color: #FFF;
    text-align: center;
  }
}
.chris-mod-2 {
  //background: #dce1ff;
  background: #25262d;
  padding: 10px;
  border-radius: 5px;
  //margin-left: 10px;
  margin-bottom: 30px;
  min-height: 240px;
  h1 {
    font-size: 13pt;
    color: #FFF;
    text-align: center;
  }
}
.chris-mod-3 {
  //background: #dce1ff;
  position: relative;
  background: #3b3c4e;
  padding: 10px;
  border-radius: 5px;
  //margin-left: 10px;
  margin-bottom: 30px;
  min-height: 240px;
  h1 {
    font-size: 13pt;
    color: #FFF;
    text-align: center;
  }
}
.da-5 {
  padding-right: 5px !important;
}
.chris-modal {
  background: none !important;
  .modal-content {
    background: none !important;
    border: none !important;
    .modal-header {
      border:0 !important;
      background: #cf2c42;
      border-radius: 5px;
      margin-bottom: 10px;
      color: #FFF;
      .close {
        border-radius: 100%;
        background: #ff5c62;
        right: -15px;
        top: -15px;
        position: absolute;
        color: white;
        height: 40px;
        width: 40px;
        display: block;
        font-size: 24px;
        margin: 0;
        padding: 0;
      }

    }
    .modal-body {
      background: #f5f5f5;
      border-radius: 5px;
      agm-map {
        height: 430px;
        margin-bottom: 10px;
      }
      .form-control-file {
        color: #FFF;
        padding: 10px;
        :lang(es)::after {
  content: "Select file...";
}
      }
    }
    .modal-footer{
      border: none !important;
      padding:30px 0 0 0;
    }
  }
}
.modal-titulo {
  //background: #5d6071;
  font-size: 16pt;
  //border-radius: 5px;
  padding: 10px;
  padding-left: 0;
  color: #FFF;
  margin-bottom: 10px;
  // @include media-breakpoint-down(md) {
  //   font-size: 14pt;
  // }
}
.mapa-modal {
  border-radius: 5px;
  margin-bottom: 10px;
}
.modal-sub-titulo {
  background: #444559;
  //display: block;
  padding: 5px 100px 5px 15px;
  //padding-right: 100px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 3px solid #13fdd1;
  //width: auto;
  }
.chris-chart {
  background: #dce1ff;
  //border-radius: 5px;
  min-height: 430px;
}
.main-body .page-wrapper {
  padding: 0 !important;
  padding-top: 1rem;
}
.chris-content {
  background: #353646;
  border-radius: 5px;
  padding-top: 30px;
  //padding-bottom: 30px;
  margin-top:10px;
  margin-bottom: 30px;
}
.eco2 {
  height: 510px;
}
.fa-titulo {
  font-size:2em;
  vertical-align: -30%;
  line-height: 0.75em;
}
.color-1{
  //color:orange !important;
  color: $primary-color !important;
  a{
    //color: orange !important;
    color: $primary-color !important;
  }
}
.color-2 {
  //color:#14FDD1 !important;
  color: $primary-color !important;
   a{
    //color: #14FDD1 !important;
    color: $primary-color !important;
  }
}
.color-3 {
  //color:#7dd6fb !important;
  color: $primary-color !important;
  a{
    //color: #7dd6fb !important;
    color: $primary-color !important;
  }
}
.color-4 {
  //color: #38a9f9 !important;
  color: $primary-color !important;
  a{
    //color: #38a9f9 !important;
    color: $primary-color !important;
  }
}
.color-5 {
  //color: #f78ea4 !important;
  color: $primary-color !important;
  a {
    //color: #f78ea4 !important;
    color: $primary-color !important;
  }
}
.color-6 {
  //color: #cea91e !important;
  color: $primary-color !important;
  a {
    //color: #cea91e !important;
    color: $primary-color !important;
  }
}
.color-7 {
  //color: #956b5f !important;
  color: $primary-color !important;
  a {
    //color: #956b5f !important;
    color: $primary-color !important;
  }
}
.color-white {
  color: #fff;
}
.color-sub {
  color: #8a99ea;
}
.h430 {
  height: 430px !important;
  max-height: 430px, !important;
  min-height: 430px !important;
  position: relative;
}
.h360 {
  height: 360px !important;
  max-height: 360px, !important;
  min-height: 360px !important;
  position: relative;
}
.h510 {
  height: 510px !important;
  max-height: 510px, !important;
  min-height: 510px !important;
  position: relative;
}
//administracion
.administracion {
  // color: #ffffff;
  .switch {
    margin-top: 8px !important;
    margin-left: 10px !important;
  }

}
.admin-table{
  overflow-x: scroll;
  color: black;
  font-size: 11pt;
  border:none !important;
  //border: solid 1px #353647 !important;
  border-radius: 5px;
  text-align: center;
  datatable-body-cell {
    padding: 1.5rem;
    + datatable-body-cell {
      border-left: 1px solid #b1b1b1;
    }
  }
    .datatable-header,.datatable-header-cell-label {
    background: #ba1d2d !important;
    color: #ffffff !important;
    border: none;
    //border-radius: 10px;
    font-weight: bold;
  }
  .datatable-footer {
    background: none;
    border: none !important;
    color: $primary-color;
    text-align: left;
  }
  .datatable-header-cell {
    //border-left:solid 1px blue;
    + datatable-header-cell {
      border-left: 1px solid #b1b1b1;
    }
  }
  .datatable-body-cell {
    //border-left:none !important;
    vertical-align: middle;
    &.active {
      background: none !important;
      color: #222 !important;
    }
  }
  .datatable-row-wrapper:nth-child(odd) {
    background: #F3F3F3;
  }
  .datatable-row-wrapper:nth-child(even) {
    background: #f3f3f3;
  }


  .datatable-body-cell-label{
    line-height: 50px;
  }
  .datatable-row-wrapper {
    background: #e7e7e7;
  }
  .datatable-pager {
    color: $primary-color;
    ul,li,a{
      color: #cf2c42;
      .active a {
        background: #7b1421 !important;
      }
    }
    &.disabled a {
      color: red !important;
    }
  }
  .datatable-scroll {
    //display: inline-block;
    //display: none !important;
    box-shadow: none;
    background: red;
  }

  .btn-group > .btn:first-of-type {
      margin-left: 0;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
}
.btn-group > .btn:last-of-type,
.btn-group > .dropdown-toggle {
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
}
  .edit-btn {
      //border-top-left-radius: 15px;
      //border-bottom-left-radius: 15px;
      background: #fff;
      color: #00180B;
      &:hover {
        background: $primary-color;
        color: #fff;
      }
  }
  .edit-btn-solo {
    background: #fff;
    color: #00180B;
    &:hover {
      background: #8b1826;
      color: #fff;
    }
  }
  .del-btn {
    //border-top-right-radius: 15px;
    //border-bottom-right-radius: 15px;
    background: #fff;
    color: #00180B;
    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.normal-btn {
  background: $primary-color;
  background-color: $primary-color!important;
  color: #ffb8ac!important;
  &:hover {
    color: #fff;
    background: #fc2630;
    background-color: #fc2630!important;
  }

}
.normal-input {
  background: #ffa099;
  border: solid 1px $primary-color;
  color: #fff;
  &:placeholder {
    color: #fff !important;
  }
}
/*
.del-btn {
  color: #FFA3A5;
  background: #AF324A;
  &:hover {
    color: #fff;
    background: #892539;
  }
}
.edit-btn {
  color: #0D3C23;
  background: #4BE08D;
  //border-radius: 10px;
  &:hover {
    color: #fff;
    background: #2E965E;
  }
}
*/
.fix-btn {
  padding: 10px;
}
.backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1049;
}
.swal2-popup {
  background: #353646 !important;
  color: #fff !important;
  h2{
    color: #fff !important;
  }
  .swal2-content {
    color: #fff !important;
  }
}
.img-panel {
  position: absolute;
  right: 0;
  top: 0;

}
.btn-inicio {
  h4 {
    font-size: 15pt;
  }

  a {
  padding: 2px;
  color: #5B5C78!important;
  :hover {
    color: #353646;
  }
  }
  &:hover {
    color: #797B9E !important;
    box-shadow: none !important;
    background: #C5EFF6;
  }

}
nav .pcoded .pcoded-header {
  background: red !important;
}
.select-fix-chris {
  .single {
  background: #8997ED !important;
  }
}
.empresa-form-img {
  margin: 10px;
  border-radius: 5px;
}
.alert-chris-danger {
  border-radius: 5px;
  margin: 10px;
  background: #d52943;
  color: #fff;
  padding: 10px;
  code {
    font-size: 10pt;
    color: #8b1728;
    font-weight: bold;
    text-transform: uppercase;
    background: #fff;
    border-radius: 5px;
    padding: 5px;
  }
}
.chris-modal-fix {
  &.modal-dialog {
    max-width: 65% !important;
  }
}
.chris-fix-input-group {
  margin-bottom: 0 !important;
  .input-group-text {
    background: $primary-color;
  }

}
.chris-label {
  //line-height: 0 !important;
}
.chris-modal-fix-design {
  &.modal-dialog {
    max-width: 50% !important;
  }
}

.custom-control{

	&.material-checkbox{
		--color: #26a69a;

		.material-control-input{
			display: none;
			&:checked~.material-control-indicator{
				border-color: var(--color);
				transform: rotateZ(45deg) translate(1px, -5px);
				width: 10px;
				border-top: 0px solid #fff;
				border-left: 0px solid #fff;
			}
		}
		.material-control-indicator{
			display: inline-block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 16px;
			height: 16px;
			border: 2px solid #aaa;
			transition: .3s;
		}
	}

	&.fill-checkbox{
		--color: #26a69a;

		.fill-control-input{
			display: none;
			&:checked~.fill-control-indicator{
				background-color: var(--color);
				border-color: var(--color);
				background-size: 80%;
			}
		}
		.fill-control-indicator{
			border-radius: 3px;
			display: inline-block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 16px;
			height: 16px;
			border: 2px solid #aaa;
			transition: .3s;
			background: transperent;
			background-size: 0%;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		}
	}

	&.overflow-checkbox{
		.overflow-control-input{
			display: none;

			&:checked~.overflow-control-indicator{
				&::after{
					transform: rotateZ(45deg) scale(1);
					top: -6px;
					left: 5px;
				}

				&::before{
					opacity: 1;
				}
			}
		}
		.overflow-control-indicator{
			border-radius: 3px;
			display: inline-block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 16px;
			height: 16px;
			border: 2px solid #aaa;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 16px;
				transition: .3s;
				transform: rotateZ(90deg) scale(0);
				width: 10px;
				border-bottom: 4px solid #aaa;
				border-right: 4px solid #aaa;
				border-radius: 3px;
				top: -2px;
				left: 2px;
			}

			&::before{
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 16px;
				transition: .3s;
				width: 10px;
				border-right: 7px solid #fff;
				border-radius: 3px;
				transform: rotateZ(45deg) scale(1);
				top: -4px;
				left: 5px;
				opacity: 0;
			}
		}
	}

	&.material-switch{
		--color: #26a69a;
		padding-left: 0;

		.material-switch-control-input{
			display: none;
			&:checked~.material-switch-control-indicator{

				&::after{
					background-color: var(--color);
					left: 17px;
				}
			}
		}
		.material-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 16px;
			background: #ddd;
			border-radius: 16px;
			transition: .3s;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				transition: .3s;
				top: -1px;
				left: -1px;
				background: #fdfdfd;
				box-shadow: 0 2px 10px #aaa;
			}
		}
	}

	&.ios-switch{
		--color: #4cd964;
		padding-left: 0;

		.ios-switch-control-input{
			display: none;

			&:active~.ios-switch-control-indicator{
				&::after{
					width: 20px;
				}
			}

			&:checked{
				~.ios-switch-control-indicator{
					border: 10px solid var(--color);

					&::after{
						top: -8px;
						left: 4px;
					}
				}

				&:active~.ios-switch-control-indicator{
					&::after{
						left: 0px;
					}
				}
			}
		}
		.ios-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 20px;
			//background: #1a1b23;
			border-radius: 16px;
			transition: .3s;
			border: 2px solid #ddd;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 16px;
				border-radius: 16px;
				transition: .3s;
				top: 0px;
				left: 0px;
				background: #fff;
				box-shadow: 0 0 2px #aaa, 0 2px 5px #999;
			}
		}
	}

	&.border-switch{
		--color: #4cd964;
		padding-left: 0;

		.border-switch-control-input{
			display: none;
			&:checked~.border-switch-control-indicator{
				border-color: var(--color);

				&::after{
					left: 14px;
					background-color: var(--color);
				}
			}
		}
		.border-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 20px;
			background: #fff;
			border-radius: 16px;
			transition: .3s;
			border: 2px solid #ccc;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transition: .3s;
				top: 2px;
				left: 2px;
				background: #ccc;
			}
		}
	}

	&.teleport-switch{
		--color: #4cd964;
		padding-left: 0;

		.teleport-switch-control-input{
			display: none;
			&:checked~.teleport-switch-control-indicator{
				border-color: var(--color);

				&::after{
					left: -14px;
				}

				&::before{
					right: 2px;
					background-color: var(--color);
				}
			}
		}
		.teleport-switch-control-indicator{
			display: inline-block;
			position: relative;
			margin: 0 10px;
			top: 4px;
			width: 32px;
			height: 20px;
			background: #fff;
			border-radius: 16px;
			transition: .3s;
			border: 2px solid #ccc;
			overflow: hidden;

			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transition: .3s;
				top: 2px;
				left: 2px;
				background: #ccc;
			}

			&::before{
				content: '';
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transition: .3s;
				top: 2px;
				right: -14px;
				background: #ccc;
			}
		}
	}
}

.list-group{
	&:first-child .list-group-control:first-child {
		border-top-left-radius: .25rem;
		border-top-right-radius: .25rem;
	}

	&::last-child .list-group-control:last-child {
		border-bottom-left-radius: .25rem;
		border-bottom-right-radius: .25rem;
	}

	&.list-group-flush{
		&:last-child{
			.list-group-control:last-child {
				border-bottom: 0;
				margin-bottom: 0;
			}
		}

		&:first-child{
			.list-group-control:first-child {
				border-top: 0;
			}
		}

		.list-group-control {
			border-right: 0;
			border-left: 0;
			border-radius: 0;
		}
	}

	.list-group-control{
		position: relative;
		display: block;
		margin-bottom: -1px;
		background-color: #fff;
		border: 1px solid rgba(0,0,0,.125);

		.custom-control{
			margin: 0;
			padding: .75rem 1.25rem;
			padding-left: 3rem;
			display: block;

			.custom-control-indicator, .material-control-indicator, .fill-control-indicator, .overflow-control-indicator{
				top: 1rem;
				left: 1.25rem;
			}
		}
	}
}
.tab-title-modal {
  color: $primary-color;
  font-size: 16pt;
  display: block;
  margin-bottom: 15px;
  @extend .col-sm-12;
}
.btn-upload-img {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  &:after {
    content: '\f093';
    font-family: FontAwesome;
    color: #fff;
    display: block;
    position: absolute;
    background: #bc1a28;
    border-radius: 5px;
    font-size: 14pt;
    padding: 5px 10px 5px 10px;
    bottom: 15px;
    right: calc(50% - 75px);
  }
}

ng-select.modal-ng-select > div > div.multiple {
  padding:5px !important;
  //height: 42px;
  overflow: hidden;
  background: #fff;
  color: #495057;
  ::placeholder {
    color:#6c757d;
    padding-left: 10px;
  }
}
.chris-slider {
  ngb-carousel{
    // slider con flechas dentro
    //margin: 0 auto;
    //width:150px !important;
  }
  .carousel-indicators {
    display: none;
  }
  img {
    border-radius: 5px;
    height: 150px;
    width: 150px;
  }
  .chris-img-slider {
    position: relative;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
  }
}
.chris-line {
  border-bottom:solid 1px #8997ED;
  color: #fff;
  line-height: 18pt;
  height: 30px;
}
.bg-seleccion {
  background: #4d4f63;
  padding: 10px;
  border-radius: 5px;
}
.chris-radius {
  border-radius: 5px;
}
.bg-chris-title {
  background: #4d4f63;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  input {
    background: #aea8d3;
    color: #4d4d4d;
    border: 1px solid #414354;
  }
}
.modal-chris-border {
  border:solid 1px #bebebe;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

//pagina principal
.block-princi{
  //height: 200px;
  width: 100%;
  min-height: 200px;
  margin-bottom: 30px;
  a {
    color:#fff;
    opacity: 0;
    transition: 1s;
    animation-delay: 2s;
  }
  ul {
    //background: green;
    display: inline-block;
    vertical-align: middle;
    flex-wrap: wrap;
  justify-content: space-around;
  }
  h1{
    font-size: 70pt;
    font-weight: lighter;
  }
.princi-title {
  font-size: 18pt;
  color: #fff;
}

}

.block-bg-1 {
  background: linear-gradient(135deg, #81d6f9 0%,#5A62A8 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-2 {
  background: linear-gradient(135deg, #4099ff 0%,#325290 100%);
  background-size: 200% auto;
  background-position:90% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-3 {
  background: linear-gradient(135deg, #AF8080 0%,#ff1a00 100%);
  background-size: 200% auto;
  background-position:90% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-4 {
  background: linear-gradient(135deg, #CB60B3 0%,#C99BBE 100%);
  background-size: 200% auto;
  background-position:90% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-5 {
  background: linear-gradient(135deg, #F4B7C3 0%,#F2607B 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-6 {
  background: linear-gradient(135deg, #0EB7AC 0%,#14F3FF 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-7 {
  background: linear-gradient(135deg, #BAAE6F 0%,#BCA000 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-8 {
  background: linear-gradient(135deg, #8ED84E 0%,#8a99ea 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-9 {
  background: linear-gradient(135deg, #8a99ea 0%, #262731 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.block-bg-10 {
  background: linear-gradient(135deg, #ab7967 0%, #AA360F 100%);
  background-size: 200% auto;
  background-position:100% 0;
  transition: 1s linear;
  &:hover {
    background-position:0 0;
    a {
      padding: 5px;
      margin: 5px;
      color:#414354;
      opacity: 1;
      transition: 0.3s;
      &:hover {
        margin-left: 10px;
        padding-left: 10px;
        background: #8a99ea;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.fix-sel-chris {
  li {
    color: #495057;
  }
}
.pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a {
  color: red !important;
}
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
  background: #be1622 !important;
  li {
  a:hover {
    background: #fc2630 !important;
    color: #fff !important;
    }
  }
}
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a {
  background: #cf2c42 !important;
  color: #fff !important;
}
.ngx-datatable{
  overflow: scroll;
}
ngx-datatable{

  overflow: scroll;
  ::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #be1622;
  border-radius: 10px;
}
}
.tam-min {
  min-height: 270px;
}
.no-bg {
  background: none !important;
}
.footer-logo {
  border-right: 2px solid #ccc;
  padding: 10px;
}
.footer {
  margin-top: 180px;
  padding-top: 80px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.footer-links {
  color:#595f64;
  a {
    //text-decoration: underline;
    padding-right: 20px;
    font-size: 9pt;
    color: #939393;
    //border: 1px solid #939393;
    //border-radius: 5px;
    &:hover {
      text-decoration: underline;
      color: red;
    }
  }

}
.footer-contenido {
  font-size: 9pt;
  color: #ccc;
  padding-top: 10px;

}
.footer-color {
  color: red;
}
.panel-resumen {
  background: #fff;
  padding: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-top: 20px;
}
.edit-btn-none {
  background: none !important;
  color: $primary-color;
  padding: 0;
  margin-top: -12px;
  font-size: 26px;

  &:hover {
    background: none !important;
    color: #7b1421;
  }
}
.swal2-popup {
  background: #7c121f !important;
  color: #fff !important;
  h2 {
    color: #ffb8ac !important;
  }
  pre {
    color: #fff;
  }
}
.input-group-append .input-group-text, .input-group-prepend .input-group-text {
  background: #cf2c42 !important;
}
.fix-entidad-box {
  margin-top: -35px !important;
  // @include media-breakpoint-down(sm) {
  //   margin-top: 0 !important;
  // }
}
.chris-muestra-txt {
  //border:solid 1px #bebebe;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  margin-bottom: 10px;
}
.txt-entidad {
  color: $primary-color;
  font-weight: bold;
}
.head-login {
  height: 80px;
  background: #fff;
  opacity: 0.8;
}
.box-inicio {
  padding-top: calc(10% - 10px);
}
.footer-login {
  background: #8b1826;
  height: 100px;
  margin-top: calc(12% - 100px);
  color: #de949d;
  font-size: 0.775em;

}
.icon-login-page {
  color: #d52943 !important;
}
.login-color  {
    color: #fff !important;
    &:hover {
      color: #F4B7C3;
    }
}
.login-links {
  a {
    color: #F4B7C3;
    &:hover {
      color: rgb(247, 215, 222);
      text-decoration: none;
    }
  }

}
.del-footer-chris {
  display: none !important;
  background: orange !important;
}

//admvouchers
.admvouchers-table{
  border-radius: 0 !important;
  font-size: 9pt !important;
  color: #727272 !important;
  font-weight: 500 !important;
  .admin-table{
    border-radius: 0 !important;
    font-size: 9pt !important;
    color: #727272 !important;
    font-weight: 500 !important;
  }
  .datatable-header-cell {
    // padding: 10px 0px !important;
  //  display: flex !important;
  //  align-items: center !important;
  //  text-align: center !important;
  //  justify-content: center !important;
  }
  .datatable-header-cell-label{
    font-weight: bold !important;
    line-height: 2.4 !important;
    padding: 10px 0px !important;
  }
  .datatable-body-cell-label{
    // padding: 10px 0px !important;
    line-height: normal;
  }
  .datatable-body-cell {
   display: flex !important;
   align-items: center !important;
   text-align: center !important;
   justify-content: center !important;
  }

  // background: blue;
  datatable-body-cell {
    padding: 5px;
    + datatable-body-cell {
      border-left: 1px solid #eceeef;
      background: #f3f3f3;
    }
  }
  datatable-header-cell {
    &.sortable {
      &:before, &:after {
        display: none;
        font-family: 'IcoFont' !important;
        position: absolute;
        top: 15px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
  .datatable-icon-right1:before {
    //font-awesome chevron-left
    content: "\f054";
    font-family: 'fontawesome' !important;
    background: #cf2c42;
    color: #fff;
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    line-height: 30px;
    font-size: 15px;
  }
  .datatable-icon-down1:before {
    //font-awesome chevron-left
    content: "\f053";
    font-family: 'fontawesome' !important;
    background: #cf2c42;
    color: #fff;
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    line-height: 30px;
    font-size: 15px;
  }
  datatable-header-cell {
    text-align: center;
    border-left: 2px solid #fff !important;
  }
  datatable-body-cell{
    text-align: center;
    border-left: 2px solid #fff !important;
    border-bottom: 2px solid #fff !important;
  }
}
.admvouchers-table .datatable-header .datatable-header-cell .datatable-header-cell-label {
  // background: #1d3cba !important;
  color: #ffffff !important;
  border: none;
  //border-radius: 10px;
  font-weight: lighter;
  font-size: 9pt;
}
.input-group-prepend-a{
  background: #fff;
  .input-group-text {
    background: #fff;
    border-right: none;
    color: #B71E2E;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: solid 2px #B71E2E;
    border-right: none;
    height: 100%;
  }
}
.search-a{
  background: #fff !important;
  border: 1px solid #ced4da;
  border-radius: 5px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: solid 2px #B71E2E;
  border-left: none;
  width: calc(100% - 50px);
  font-size: 8pt;
}
.btn-crear-tarj{
  border: solid 2px #B71E2E !important;
  border-radius: 5px !important;
  background: #fff;
  color: #B71E2E !important;
  font-size: 10pt !important;
  font-weight: bold !important;
  text-transform: none !important;
}
.btn-crear-expo{
  border: solid 2px #B71E2E !important;
  border-radius: 5px !important;
  background: #fff;
  color: #B71E2E !important;
  font-size: 8pt !important;
  font-weight: bold !important;
}
.btn-crear-action{
  border: solid 1px #d9d9d9 !important;
  border-radius: 10px !important;
  background: #fff !important;
  color: #727272 !important;
  font-size: 8pt !important;
  font-weight: bold !important;
}
.nine-table{
  // width: 100% !important;
  // background: blue;
  border-left: 2px solid #fff;
  font-size: 8pt;
  display: block;
  padding: 0px 5px;
  width: 160px;
  min-width: 160px;
}
.title-expa{
  color:#fff;
  background: #BA1C2D;
  display: block;
  width: 100%;
  padding: 5px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 10px;
  // border: 1px solid #acacac;
  // border-bottom: 2px solid #BA1C2D;
}
.title-expa-clean{
  color:#fff;
  background: #C2C2C2;
  display: block;
  width: 100%;
  padding: 5px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 10px;
  // border: 1px solid #acacac;
  // border-bottom: 2px solid #C2C2C2;
}
.content-expa{
  background: #fff;
  display: block;
  width: 100%;
  padding: 20px 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  //shadow
  box-shadow: 0px 0px 2px 0px #656565;
  margin-bottom: 10px;
}
.estados-adm{
  background: #fff;
  color: #727272;
  font-size: 9pt;
  border-radius: 15px;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #d9d9d9;
}
.estados-adm-activo{
  color: #4CAF50;
}
.estados-adm-inactivo{
  color: #7d7d7d;
}
.estados-adm-problemas{
  color: #ff8307;
}
.label-outline{
  background: #fff;
  color: #727272;
  font-size: 9pt;
  border-radius: 15px;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #d9d9d9;
  span.success{
    color:#3cb32c;
  }
  span.warning{
    color:#ff8307;
  }
  span.inactive{
    color:#7d7d7d;
  }
  span.danger{
    color: #ec0d0a;
  }
}

.info-search{
  background: #727272;
  color: #fff;
  font-size: 7pt;
  height: 25px;
  border-radius: 50px;
  width: 30px;
  text-align: center;
  margin-right: 10px;
  line-height: 25px;
  margin-top: -15px;

}
.list-saldo{
  //list of 3 columns
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  // justify-content: space-around;

}
.item-saldo{
  flex-grow: 1;
  //width: calc(33.33% - 10px);
  margin: 5px;
  display: flex;
  justify-content: space-between;
  //max-width: calc(33.33% - 10px);
  max-width: calc(50% - 10px);
  flex: 1 1 45%;
  // max-width: 100%;
  // width: 100%;
  // border-bottom: 1px #d9d9d9 dashed;
}
.title-saldo{
  color: #fff;
  font-size: 9pt;
  font-weight: 500;
  border: solid 1px #d9d9d9;
  background: #B71E2E;
  width: 49.5%;
  border-radius: 5px;
  padding-left: 8px;
  line-height: 2.3;
}
.res-saldo{
  color: #888787;
  font-size: 9pt;
  border: solid 1px #d9d9d9;
  height: 30px;
  // padding: 5px 30px;
  min-width: 160px;
  border-radius: 5px;
  text-align: right;
  font-weight: bold;
  width: 49.5%;
  border-radius: 5px;
  padding-right: 8px;
  line-height: 2.3;
}
.icon-expa{
  color: #fff;
  font-size: 18pt;
  width:22px;
  // height: 40px;
  text-align: center;
  // margin-left: 0.15rem;
  // line-height: 20px;
}
.link-expa{
  text-align: center;
  display: block;


}
.filtro-select{
  margin-bottom: 0.50rem;

  .ng-select-container {
    padding: 0px !important;
    font-size: 8pt !important;
    border-radius: 6px !important;
    // border: 0 !important;
    min-height: 40px !important;
  }
  .ng-dropdown-panel{
    border: 3px solid #ca0a0a !important;
    border-radius: 6px !important;
  }
  .ng-arrow-wrapper::after {
    //bootstrap icon chevron-down
    content: "\f078" !important;
    font-family: 'fontawesome' !important;
    // background: #ca0a0a;
  }
  .ng-arrow{
    display: none !important;
  }
}

.table-proceso{
  background: #fff;
  color: #727272;
  font-size: 9pt;
  border-radius: 15px;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #d9d9d9;
  span{
    height: 10px;
    width: 10px;
    display: block;
    margin-right: 5px;
    background: #808080;
  }
  .encurso{
    span{
      background: #4CAF50;
    }
  }
  .terminado{
    span{
      background: #ec0d0a;
    }
  }
  .pendiente{
    span{
      background: #24ff07;
    }
  }
}
.table-proceso{
  background: #fff;
  color: #727272;
  font-size: 9pt;
  border-radius: 15px;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #d9d9d9;
  .activo{
    color:#3cb32c;
  }
  .problemas{
    color:#ff8307;
  }
  .inactivo{
    color:#7d7d7d;
  }
}


/*
 ALEJANDRA
 */
/*
.contenido {
  //background-color: #6E7287;
  //background-color: #353647;
  background-color: $background-content-color;
  border: 0.25px solid #8D8D8D;
  padding: 16px;
  //padding-bottom: 50px;
  //border: solid 1px #545767;
  border-radius: 6px;
}
.page-encabezado {
  //background: #4D4E64;
  border-radius: 5px;
  padding: 8px;

  h1 {
    color: $light-color;
    font-size: 16px;
    padding: 10px;
    font-weight: bold;
  }
}

.admin-table {
  .datatable-row-wrapper:nth-child(odd) {
    background: #d5d5d5;
  }
}

.normal-btn {
  background: $primary-color!important;
  background-color: $primary-color!important;
  color: #fbfbfb!important;
  &:hover {
    color: #fbfbfb;
    background: $secondary-color;
    background-color: $secondary-color!important;
  }
}
*/
