/**  =====================
      Breadcrumb css start
==========================  **/
.caption-breadcrumb{
	.breadcrumb-header{
		display:inline-block;
		span{
			display: block;
			font-size: 13px;
			margin-top: 5px;
		}
	}
}

.front-icon-breadcrumb{
	.breadcrumb-header{
		display: inline-block;
	}
	.big-icon{
		display: inline-block;
		i{
			font-size: 50px;
			margin-right: 10px;	
			color: $primary-color;
		}
	}
	.d-inline-block{
		span{
			display: block;
			font-size: 13px;
			margin-top: 5px;
		}
	}
}

.primary-breadcrumb{
	@extend .caption-breadcrumb;
	background:linear-gradient(45deg, $primary-color, lighten($primary-color,10%));
	color: #fff;
	h5,a,.breadcrumb-title li:last-child a,.breadcrumb-item+.breadcrumb-item::before{
		color: #fff;
	}
}
.inverse-breadcrumb{
	@extend .primary-breadcrumb;
	background:linear-gradient(45deg, $inverse-color, lighten($inverse-color,10%));

}
.danger-breadcrumb{
	@extend .primary-breadcrumb;
	background:linear-gradient(45deg, $danger-color, lighten($danger-color,10%));
}
.info-breadcrumb{
	@extend .primary-breadcrumb;
	background:linear-gradient(45deg, $info-color, lighten($info-color,10%));
}
.warning-breadcrumb{
	@extend .primary-breadcrumb;
	background:linear-gradient(45deg, $warning-color, lighten($warning-color,10%));
}
.success-breadcrumb{
	@extend .primary-breadcrumb;
	background:linear-gradient(45deg, $success-color, lighten($success-color,10%));
}

/**====== Breadcrumb css end ======**/
.page-header-breadcrumb{
	float: right;
}