// tab below bootstrap
.below-tabs {
  transform: rotate(180deg);
  .nav-tabs, .tab-content {
    transform: rotate(180deg);
  }
  .nav-tabs {
    .nav-link {
      border: 1px solid transparent;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
    border-top: 1px solid #ddd;
    border-bottom: transparent;
    .nav-item.show .nav-link, .nav-link.active {
      color: #464a4c;
      background-color: #fff;
      border-color: #fff #ddd #ddd;
      margin-top: -1px;
    }
  }
}

/* tab material start */

.md-tabs {
  &.md-card-tabs {
    .nav.nav-tabs {
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
      border: none;
      margin-bottom: 30px;
      transition: all 0.3s ease-in-out;
    }
  }
  .nav-link {
    &.active {
      border-bottom: 4px solid $color-blue;
      border-bottom: 4px solid $color-blue;
      &:focus, &:hover {
        border-bottom: 4px solid $color-blue;
      }
    }
    &:hover,
    &:focus{
      border-bottom:4px solid #ddd;
    }
    transition: all 0.4s ease-in-out;
    border-bottom: 4px solid transparent;
  }
  &.md-below-tabs {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    .nav-tabs, .tab-content {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .nav-tabs, .nav-item:first-child {
      border-top: 1px solid #ddd;
      border-bottom: 0px solid transparent;
      margin-top: -1px;
    }
    .nav-link {
      &.active {
        border-top: 4px solid $color-blue;
        border-top: 4px solid $color-blue;
        border-bottom: 0px solid transparent;
        &:focus, &:hover {
          border-top: 4px solid $color-blue;
          border-bottom: 0px solid transparent;
        }
      }
      transition: all 0.4s ease-in-out;
      border-top: 0px solid transparent;
    }
    .nav-tabs .nav-link {
      &:hover {
        border-top: 4px solid #ddd;
        border-bottom: 0px solid transparent;
      }
      &.active:hover {
        border-top: 4px solid $color-blue;
      }
    }
  }
  &.md-left-tabs {
    .nav-item {
      width: 100%;
      &:first-child {
        border-bottom: 0px;
      }
      .nav-link {
        border: 0px solid transparent;
        padding: 10px 0 !important;
        margin: 10px 0;
      }
    }
    .nav-link.active {
      border-left: 4px solid $color-blue;
      border-bottom: 0px solid transparent;
      &:focus, &:hover {
        border-left: 4px solid $color-blue;
        border-bottom: 0px solid transparent;
      }
    }
    .nav-item .nav-link.active {
      border: 0px solid transparent;
      border-left: 4px solid $color-blue;
    }
    .nav-tabs {
      .nav-link {
        &.active:hover {
          border-left: 4px solid $color-blue;
        }
        &:hover {
          border: 0px solid transparent;
          border-left: 4px solid #ddd;
        }
      }
      border-bottom: 0px solid transparent;
      display: table-cell;
      min-width: 120px;
      vertical-align: top;
      width: 150px;
    }
    .tab-content {
      display: table-cell;
      top: 0;
      position: absolute;
      left: 150px;
    }
  }
  &.md-right-tabs {
    .nav-item {
      width: 100%;
      &:first-child {
        border-bottom: 0px;
      }
      .nav-link {
        border: 0px solid transparent;
        padding: 10px 0 !important;
        margin: 10px 0;
      }
    }
    .nav-link.active {
      border-right: 4px solid $color-blue;
      border-bottom: 0px solid transparent;
      &:focus, &:hover {
        border-right: 4px solid $color-blue;
        border-bottom: 0px solid transparent;
      }
    }
    .nav-item .nav-link.active {
      border: 0px solid transparent;
      border-right: 4px solid $color-blue;
    }
    .nav-tabs {
      .nav-link {
        &.active:hover {
          border-right: 4px solid $color-blue;
        }
        &:hover {
          border: 0px solid transparent;
          border-right: 4px solid #ddd;
        }
      }
      border-bottom: 0px solid transparent;
      display: table-cell;
      min-width: 120px;
      vertical-align: top;
      width: 150px;
      float: right;
    }
    .tab-content {
      display: table-cell;
      top: 0;
      position: absolute;
      right: 150px;
    }
  }
  &.md-image-tab {
    .nav-link {
      position: relative;
      display: inline-block;
    }
    .nav-item, .nav-link, .nav-tabs {
      border: 0px solid transparent !important;
    }
    img {
      width: 100px;
      border: 4px solid $color-blue;
      + .quote {
        position: absolute;
        height: 25px;
        width: 25px;
        border-radius: 100%;
        bottom: 10px;
        right: 70px;
      }
    }
    .quote i {
      border-radius: 50%;
      padding: 3px 5px;
    }
    .nav-link {
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 576px) {

  ngb-tabset .nav-tabs .nav-link.active {
    border: 1px solid transparent;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  .md-tabs {
    .nav {
      display: block;
      &.nav-tabs {
        width: 100%;
        a.nav-link {
          margin-bottom: 0;
        }
      }
    }
    .nav-tabs .nav-link.active {
      border: none;
      border-bottom: 4px solid $color-blue;
    }
    &.md-below-tabs {
      display: block;
      .nav-tabs .nav-link.active {
        border: none;
        border-bottom: 4px solid transparent;
        border-top: 4px solid $color-blue;
      }
    }
    &.md-right-tabs, &.md-left-tabs {
      .nav-tabs {
        display: block;
      }
      .tab-content {
        position: relative;
        left: 0;
      }
    }
  }
}

.pcoded[layout-type="dark"], body.dark {
  .md-tabs .nav-item a.active, .md-tabs .nav-item a:focus, .md-tabs .nav-item a:hover {
    border-color: $primary-color !important;
  }

  .below-tabs .nav-tabs {
    border-top: none;
  }
  .md-tabs.md-below-tabs .nav-link.active {
    margin-top: -1px;
  }

  .md-tabs.md-card-tabs .nav.nav-tabs {
    background-color: lighten($dark-layout-color, 5%);
  }
}
