.widget-statstic-card {
    position: relative;
    overflow: hidden;
    .card-header{
        border-bottom: 0px solid transparent;
    }
    .st-icon {
        color: #292A37;
        font-size: 23px;
        padding: 40px 40px 20px 20px;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        right: -30px;
        transition: all 0.3s ease-in-out;
    }

    h2 {
        font-weight: 600;
        display: inline-block;
    }

    span {
        border-radius: 30px;
        padding: 5px 20px;
        font-weight: 600;
    }
    &:hover{
        .st-icon{
            font-size: 50px;
        }
    }
}
.card-comment {
    transition: all ease-in-out 0.5s;

    .card-block-small {
        border-bottom: 1px solid #f1f1f1;

        .comment-desc {
            h6 {
                font-weight: 600;
            }

            .btn-comment {
                font-size: 13px;
                padding: 5px 20px;
                line-height: 1;
                color: #fff;
            }
        }

        &:hover {
            background-color: #F1F1F1;
        }
    }
    img {
        float: left;
        width: 60px;
    }

    .comment-desc {
        padding-left: 80px;
        vertical-align: top;
    }

    .comment-btn,
    .date {
        display: inline-block;
    }

    .date {
        float: right;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .card-comment .date {
            float: none;
            margin-top: 20px;
        }
    }
    @media only screen and (max-width: 575px) {
        .card-comment .comment-desc {
            padding-left: 60px;
        }

        .card-comment .date {
            float: none;
            display: block;
            margin-top: 20px;
        }
    }
}
.review-project {
    thead {
        th {
            border-bottom: none;
            border-top: none;
        }
    }

    tbody {
        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }

            td {
                h6,
                p {
                    font-weight: 600;
                    margin-bottom: 0;
                    line-height: 1.4;
                }
            }
        }
    }

    .peity {
        width: 30px;
        height: 30px;
    }

    img {
        vertical-align: top;
    }

    .project-contain {
        margin-left: 15px;
        display: inline-block;
    }

    .progress {
        width: 100%;
        height: 8px;
        background-color: #CDD8DC;
        border-radius: 30px;
        position: relative;
        overflow: inherit;

        .progress-bar {
            border-radius: 30px;
        }

        label {
            position: absolute;
            top: -22px;
            right: 0;
            color: #222;
            font-weight: 600;
            font-size: 12px;
        }
    }
}
.past-payment-card {
    thead {
        th {
            border-bottom: none;
            border-top: none;
        }
    }

    tbody {
        tr {
            &:last-child td {
                padding-bottom: 0;
            }

            td {
                vertical-align: middle;
            }

            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
    }

    img {
        width: 40px;
    }
}
.project-task {
    thead {
        th {
            border-bottom: none;
            border-top: none;
        }
    }

    tbody {
        tr {
            cursor: pointer;
            td {
                vertical-align: middle;
            }
        }
    }

    .task-contain {
        h6 {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #fff;
            line-height: 2.5;
            margin-bottom: 0;
            font-weight: 600;
            padding: 3px;
        }
    }

    .progress {
        width: 55%;
        height: 5px;
        text-align: center;
        margin: 0 auto;
        background-color: #E5E5E5;
        border-radius: 30px;

        .progress-bar {
            border-radius: 30px;
        }
    }
}
.review-task {
    .table {
        td {
            vertical-align: middle;

            img {
                display: inline-block;
            }

            p {
                display: inline-block;
                margin-bottom: 0;
            }

            h6 {
                margin-bottom: 0;
                font-size: 0.9rem;
                font-weight: 600;
            }

            .review-date {
                margin-bottom: 0;
                position: relative;
                padding-right: 15px;

                &:after {
                    content: '|';
                    position: absolute;
                    font-size: 15px;
                    padding: 0 6px;
                }
            }

            i {
                & + i {
                    margin-left: 5px;
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;
                &:first-child {
                    td {
                        border-top: none;
                    }
                }
            }
        }
    }
}
.card-power {
    h2 {
        font-weight: 500;
        display: inline-block;
    }

    span {
        font-size: 20px;
        margin-left: 5px;
    }

    .map-area {
        h6 {
            display: inline-block;
            font-weight: 600;
        }

        span {
            font-size: inherit;
            margin-left: 0;
            font-weight: 600;
        }

        p {
            line-height: 18px;
        }
    }
}
// Amount card start

.amount-card {
    color: #fff;

    h4 {
        text-align: center;
    }

    i {
        position: absolute;
        font-size: 25px;
        top: 25px;
        left: 20px;
    }

    p {
        text-align: center;
        position: absolute;
        margin-bottom: 0;
        bottom: 10px;
        left: 0;
        right: 0;
        color: $light-color;
    }

    #amunt-card1,
    #amunt-card2,
    #amunt-card3,
    #amunt-card4 {
        height: 80px !important;
        width: 100%;
    }
}

#Statistics-chart {
    height: 200px;
    width: 100%;
    @media only screen and (min-width: 1400px){
        height: 260px;
    }
}

#process-complience-chart {
    height: 200px;
    width: 100%;
}
// Amount card end
// notification card start

.notification-card {
    text-align: center;
    color: #fff;

    .card-block {
        > div {
            margin-left: 0;
            margin-right: 0;
        }
        padding: 0;

        .notify-icon i {
            font-size: 30px;
        }

        .notify-cont {
            padding: 15px 0;
            border-left: 1px solid #fff;

            p {
                margin-bottom: 0;
            }
        }
    }
}
// notification card end
// order card start

.order-card {
    color: #fff;

    i {
        font-size: 26px;
    }
}
// order card end
// user card start

.user-card {
    .card-block {
        text-align: center;

        .usre-image {
            position: relative;
            margin: 0 auto;
            display: inline-block;
            padding: 5px;
            width: 110px;
            height: 110px;

            img {
                z-index: 20;
                position: absolute;
                top: 5px;
                left: 5px;
            }

            &:after,
            &:before {
                content: "";
                width: 100%;
                height: 48%;
                border: 2px solid $primary-color;
                position: absolute;
                left: 0;
                z-index: 10;
            }

            &:after {
                top: 0;
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;
            }

            &:before {
                bottom: 0;
                border-bottom-left-radius: 50px;
                border-bottom-right-radius: 50px;
            }
        }

        .activity-leval {
            li {
                display: inline-block;
                width: 15%;
                height: 4px;
                margin: 0 3px;
                background-color: #ccc;

                &.active {
                    background-color: $success-color;
                }
            }
        }

        .counter-block {
            color: #fff;

            p {
                margin-bottom: 0;
            }

            i {
                font-size: 20px;
            }
        }

        .user-social-link i {
            font-size: 30px;
        }
    }
}
// user card end
// user visitor card strat

.map-visitor-card {
    .card-header {
        h5 {
            color: #fff;
        }
    }

    .card-footer {
        background-color: #fff;

        .progress {
            height: 4px;
        }
    }
}
// user visitor card end
// feed blog start

.feed-blog {
    border-left: 1px solid #d6d6d6;
    margin-left: 30px;

    li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 50px;

        h6 {
            line-height: 1.5;
            cursor: pointer;
        }

        .feed-user-img {
            img {
                width: 40px;
                height: 40px;
            }
            position: absolute;
            left: -20px;
            top: -10px;

            &:after {
                content: "";
                position: absolute;
                top: 3px;
                right: 3px;
                border: 3px solid transparent;
                border-radius: 50%;
            }
        }

        &.active-feed {
            .feed-user-img:after {
                border-color: $success-color;
            }
        }

        &.diactive-feed {
            .feed-user-img:after {
                border-color: $danger-color;
            }
        }
    }
}
// feed blog end
// seo-card start

.seo-card {
    .overlay-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-color: #000;
    }

    .seo-content {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;

        h4,
        h6 {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 50px;
                height: 2px;
                background-color: #fff;
            }
        }
    }

    .seo-statustic {
        position: absolute;

        &+.seo-chart {
            margin-top: 60px;
        }
    }
}
#seo-card1,
#seo-card2{
    height: 120px;
    @media only screen and (min-width: 1400px){
        height: 150px !important;
    }
}
// seo-card end
// tabs card start

.tabs-card {
    .table th{
        border-top: 0px solid transparent;
    }
    .md-tabs {
        .nav-item {
            // position: relative;
            // text-align: left;

            i {
                font-size: 15px;
                margin-right: 15px;
            }

            // a {
            //     padding: 15px 10px !important;
            // }

            // .nav-link {
            //     ~ .slide {
            //         opacity: 1;
            //         width: auto;
            //         left: 10px;
            //         right: 10px;
            //
            //         &.bg-c-blue {
            //             background: linear-gradient(45deg,$color-blue,lighten($color-blue,20%));
            //         }
            //
            //         &.bg-c-green {
            //             background: linear-gradient(45deg,$color-green,lighten($color-green,20%));
            //         }
            //
            //         &.bg-c-pink {
            //             background: linear-gradient(45deg,$color-pink,lighten($color-pink,20%));
            //         }
            //
            //         &.bg-c-yellow {
            //             background: linear-gradient(45deg,$color-yellow,lighten($color-yellow,20%));
            //         }
            //     }
            //
            //     &,
            //     &.active,
            //     &:focus,
            //     &:hover {
            //         color: $light-color;
            //     }
            // }
        }
    }
}
// tabs card end
// trnasiction card start

.trnasiction-card {
    .transection-preogress {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid transparent;
        padding: 12px 0;
        text-align: center;
        transform: rotate(45deg);

        p,
        span {
            color: #fff;
            transform: rotate(-45deg);
        }

        &.running-75 {
            border-top-color: #fff;
            border-right-color: #fff;
            border-bottom-color: #fff;
        }

        &.running-50 {
            border-top-color: #fff;
            border-right-color: #fff;
        }

        &.running-25 {
            border-top-color: #fff;
        }

        &.complited {
            border-color: #fff;
        }
    }

    .trnasiction-profile {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        padding: 20px 0;
        background-color: #ccc;
        text-align: center;
        color: #fff;
        margin: 30px auto;
    }

    .transection-footer {
        margin-left: 0;
        margin-right: 0;

        > div {
            padding: 15px 0;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: #f1f1f1,;
            }
        }
    }
}
// trnasiction card end
// user radial card start

.user-radial-card {
    text-align: center;

    > div {
        margin: 0 auto;
        display: block;

        img {
            box-shadow: 0 0 0 10px #fff;
        }
    }

    p {
        margin-bottom: 0;
    }
}
// user radial card end
// student and email card start

#email-chart,
#student-chart {
    height: 200px;
    width: 100%;
}
// student and email card end
// statustic card start

.statustic-card {
    .card-block {
        position: relative;

        .progress {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            height: 5px;
            overflow: visible;

            .progress-bar {
                position: relative;

                &:before {
                    content: "";
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    background: inherit;
                }

                &.bg-c-blue:before {
                    animation: blue-blink-bar 0.5s linear infinite;
                }

                &.bg-c-green:before {
                    animation: green-blink-bar 0.5s linear infinite;
                }

                &.bg-c-pink:before {
                    animation: pink-blink-bar 0.5s linear infinite;
                }

                &.bg-c-yellow:before {
                    animation: yellow-blink-bar 0.5s linear infinite;
                }
            }
        }
    }
}
@keyframes blue-blink-bar {
    0% {
        box-shadow: 0 0 0 0 transparentize($color-blue,0.9);
    }

    50% {
        box-shadow: 0 0 0 6px transparentize($color-blue,0.7);
    }
}
@keyframes green-blink-bar {
    0% {
        box-shadow: 0 0 0 0 transparentize($color-green,0.9);
    }

    50% {
        box-shadow: 0 0 0 6px transparentize($color-green,0.7);
    }
}
@keyframes pink-blink-bar {
    0% {
        box-shadow: 0 0 0 0 transparentize($color-pink,0.9);
    }

    50% {
        box-shadow: 0 0 0 6px transparentize($color-pink,0.7);
    }
}
@keyframes yellow-blink-bar {
    0% {
        box-shadow: 0 0 0 0 transparentize($color-yellow,0.9);
    }

    50% {
        box-shadow: 0 0 0 6px transparentize($color-yellow,0.7);
    }
}
// statustic card end
// wather card start

.wather-card {
    background: linear-gradient(to bottom, $color-blue, lighten($color-blue,25%));

    .sparcle {
        min-height: 340px;
        position: relative;

        .s-img-1,
        .s-img-2 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .s-img-1 {
            animation: sparcle 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }

        .s-img-2 {
            animation: sparcle 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }

        .mountain {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .sun {
            background: #fff;
            position: absolute;
            top: 80px;
            left: 100px;
            width: 50px;
            height: 50px;
            border-radius: 50%;

            &:after,
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 50%;
                position: absolute;
                box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4);
            }

            &:after {
                animation: sunwawe 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            }

            &:before {
                animation: sunwawe 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
                animation-delay: 1.15s;
            }
        }
    }

    .card-block {
        background-color: #fff;

        .progress {
            height: 30px;
            background-color: #fff;
            border: 1px solid #666;
            padding: 8px;
            border-radius: 15px;
            margin: 5px 20px 25px 25px;

            .progress-bar {
                border-radius: 6px;
                width: 100%;
                padding: 3px;

                .turmo-bar {
                    background-color: #fff;
                    height: 100%;
                    border-radius: 5px;
                }
            }
        }

        .progress-label {
            width: 70px;
            height: 70px;
            background-color: #fff;
            border: 1px solid #666;
            border-right-color: transparent;
            border-radius: 50%;
            position: absolute;
            top: -15px;
            left: 0;

            span {
                text-align: center;
                padding: 15px 0;
                background-color: $color-blue;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                font-size: 20px;
                position: absolute;
                color: #fff;
                top: 4px;
                left: 4px;
            }
        }
    }
}
@keyframes sparcle {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}
@keyframes sunwawe {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(4);
        opacity: 0;
    }
}

.popular-img-block {
    img {
        width: calc(100%/3.5);
        margin: 5px;
        @media only screen and (min-width: 1400px){
            width: calc(100%/4.7);
        }
    }
}
// wather card end
// social card start

.social-card {
    color: #fff;

    .owl-carousel .owl-item img {
        width: 20px;
        height: 20px;
        float: right;
    }

    .owl-dots {
        text-align: center;

        .owl-dot {
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 50%;
            margin: 0 5px;
            border: 1px solid #fff;
            background-color: transparent;
            transition: all 0.3s ease-in-out;

            &.active {
                background-color: #fff;
            }
        }
    }
}
// social card end
// chat start

.chat-card {
    .media {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .msg {
        padding: 3px 7px;
        margin: 5px 0;
        display: inline-block;
    }

    .chat-saprator {
        position: relative;
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            background-color: $theme-border;
            height: 2px;
            width: 100%;
            z-index: 1;
        }

        span {
            background-color: #fff;
            color: $color-blue;
            z-index: 99;
            position: relative;
            padding: 0 5px;
            font-style: italic;
        }
    }

    .received-chat {
        .msg {
            background-color: lighten($color-blue,30%);
        }
    }

    .send-chat {
        text-align: right;

        .msg {
            text-align: left;
            background-color: lighten($light-color,45%);
        }
    }

    .chat-control {
        border-top: 1px solid $theme-border;
        position: relative;

        .chat-form {
            .form-control {
                border: none;
                margin: 15px 0;

                &:active,
                &:focus,
                &:hover {
                    outline: none;
                    border: none;
                }
            }

            .chat-btn {
                position: absolute;
                right: 25px;
                top: 22px;
            }
        }
    }
}
// chat end
// feed blog start

.activity-blog {
    margin-left: 60px;

    li {
        position: relative;
        padding-bottom: 20px;
        padding-top: 20px;

        & + li {
            border-top: 1px solid $theme-border;
        }

        h6 {
            line-height: 1.5;
            cursor: pointer;
        }

        .activity-user-img {
            img {
                width: 40px;
                height: 40px;
            }
            position: absolute;
            left: -50px;
            top: 20;
        }
    }
}
// activity blog end
